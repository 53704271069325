import React from 'react'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import { Grid, Typography, Select, withStyles, OutlinedInput, MenuItem } from '@material-ui/core'
import StatBloc from './StatBloc'

import 'react-datepicker/dist/react-datepicker.css'

const PERIOD_30_DAYS = 1
const PERIOD_90_DAYS = 2
const PERIOD_SELECT = 3

const dateCheckRegex = new RegExp('^\\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$', 'i')

class DetailedStatistics extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      period: props.initialPeriod,
      periodDateStart: DateTime.local()
        .minus({ days: 30 })
        .toJSDate(),
      periodDateEnd: DateTime.local().toJSDate(),
    }
  }
  static defaultProps = {
    statistics: {},
    initialPeriod: PERIOD_30_DAYS,
    handleStatsUpdate: () => null,
  }

  handleOnChangePeriod = evt => {
    const period = evt.target.value
    let dateStart = this.state.periodDateStart
    switch (period) {
      case 1:
        dateStart = DateTime.local()
          .minus({ days: 30 })
          .toJSDate()
        break
      case 2:
        dateStart = DateTime.local()
          .minus({ days: 90 })
          .toJSDate()
        break
      default:
    }
    this.setState(
      {
        period,
        periodDateStart: dateStart,
        periodDateEnd: DateTime.local().toJSDate(),
      },
      () => {
        this.props.handleStatsUpdate(this.state.periodDateStart, this.state.periodDateEnd)
      }
    )
  }

  handleDateChange = type => date => {
    if (type === 'start' && date && dateCheckRegex.test(date.toISOString().substring(0, 10))) {
      this.setState({ periodDateStart: date }, () =>
        this.props.handleStatsUpdate(this.state.periodDateStart, this.state.periodDateEnd)
      )
    } else if (type === 'end' && date && dateCheckRegex.test(date.toISOString().substring(0, 10))) {
      this.setState({ periodDateEnd: date }, () =>
        this.props.handleStatsUpdate(this.state.periodDateStart, this.state.periodDateEnd)
      )
    }
  }

  render() {
    const { period, periodDateStart, periodDateEnd } = this.state
    const { classes, statistics } = this.props
    const { uncertaintyStats = {}, tasksStats = {}, contributorsStats = {} } = statistics.detailed

    return (
      <Grid container spacing={8} style={{ padding: '0 2rem' }}>
        <Grid item xs={12}>
          <Grid container>
            <FlexGridItem xs={2} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography>Period</Typography>
              <Select
                value={period}
                onChange={this.handleOnChangePeriod}
                input={
                  <OutlinedInput
                    id="period-selector"
                    notched={false}
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                <MenuItem value={PERIOD_30_DAYS}>Last 30 days</MenuItem>
                <MenuItem value={PERIOD_90_DAYS}>Last 90 days</MenuItem>
                <MenuItem value={PERIOD_SELECT}>Select Period</MenuItem>
              </Select>
            </FlexGridItem>
            <FlexGridItem xs={4}>
              {period === PERIOD_SELECT && (
                <React.Fragment>
                  <div style={{ margin: '0 2rem' }}>
                    <Typography> Date start</Typography>
                    <DatePicker
                      id="period-date-start"
                      customInput={
                        <OutlinedInput notched={false} classes={{ input: classes.outlinedInput }} />
                      }
                      selected={periodDateStart}
                      onChange={this.handleDateChange('start')}
                      dateFormat="dd-MM-yyyy"
                      startDate={periodDateStart}
                      endDate={periodDateEnd}
                      maxDate={Date.now()}
                    />
                  </div>
                  <div style={{ margin: '0 2rem' }}>
                    <Typography> Date end</Typography>
                    <DatePicker
                      id="period-date-end"
                      customInput={
                        <OutlinedInput notched={false} classes={{ input: classes.outlinedInput }} />
                      }
                      selected={periodDateEnd}
                      onChange={this.handleDateChange('end')}
                      dateFormat="dd-MM-yyyy"
                      startDate={periodDateStart}
                      endDate={periodDateEnd}
                      minDate={periodDateStart}
                      maxDate={Date.now()}
                    />
                  </div>
                </React.Fragment>
              )}
            </FlexGridItem>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subheading">Uncertainties</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: uncertaintyStats.declaredCurrentPeriod,
                o: uncertaintyStats.declaredPreviousPeriod,
              }}
              desc="Declared"
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: uncertaintyStats.closedCurrentPeriod,
                o: uncertaintyStats.closedPreviousPeriod,
              }}
              desc="Closed"
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: uncertaintyStats.averageScoreCurrentPeriod,
                o: uncertaintyStats.averageScorePreviousPeriod,
              }}
              lessIsBetter={true}
              desc="Average criticality"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subheading">Tasks</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: tasksStats.declaredCurrentPeriod,
                o: tasksStats.declaredPreviousPeriod,
              }}
              desc="Declared"
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: tasksStats.closedCurrentPeriod,
                o: tasksStats.closedPreviousPeriod,
              }}
              desc="Closed"
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: tasksStats.averageCompletionCurrentPeriod,
                o: tasksStats.averageCompletionPreviousPeriod,
              }}
              desc="Average completion"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subheading">Contributors</Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: contributorsStats.registeredCurrentPeriod,
                o: contributorsStats.registeredPreviousPeriod,
              }}
              desc="Registered"
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: contributorsStats.totalConnexionsCurrentPeriod,
                o: contributorsStats.totalConnexionsPreviousPeriod,
              }}
              desc="Total connexion(s)"
            />
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify="center">
            <StatBloc
              values={{
                n: contributorsStats.uniqueConnexionsCurrentPeriod,
                o: contributorsStats.uniqueConnexionsPreviousPeriod,
              }}
              desc="Unique connexion(s)"
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const FlexGridItem = ({ children, ...others }) => {
  return (
    <Grid item {...others} style={{ alignItems: 'center', ...others.style, display: 'flex' }}>
      {children}
    </Grid>
  )
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 150,
    maxWidth: 200,
  },
  dateTextField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  outlinedInput: {
    padding: '5px 10px',
    minWidth: 150,
  },
})

export default withStyles(styles)(DetailedStatistics)
