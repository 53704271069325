import React from 'react'
import { Button, withTheme } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { FlexDiv } from 'components/common'
import { Wing, WingContent, Container, Title, Baseline, leftFooterStyle } from './.'
import NewPasswordForm from './NewPasswordForm'

import logo from 'assets/logo.png'

class PasswordPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: {
        focused: false,
        hasUpper: false,
        hasLower: false,
        nbChar: 0,
        hasNumber: false,
        hasSpecial: false,
      },
    }
  }

  handlePasswordChange = evt => {
    const input = evt.target.value
    const password = {
      hasUpper: false,
      hasLower: false,
      nbChar: 0,
      hasNumber: false,
      hasSpecial: false,
    }
    for (let i = 0; i < input.length; i++) {
      password.nbChar = input.length
      const char = input.charAt(i)
      if (!Number.isNaN(Number.parseInt(char, 10))) password.hasNumber = true
      else if (char.match(/[@#$;%[_\]]/g)) password.hasSpecial = true
      else if (char === char.toUpperCase()) password.hasUpper = true
      else if (char === char.toLowerCase()) password.hasLower = true
    }
    this.setState({ password })
    return evt
  }

  calculatePasswordStrength = password => {
    let strenghLevel = 0
    if (password.hasUpper && password.hasLower && password.hasNumber) {
      if (password.nbChar >= 8 && !password.hasSpecial && password.nbChar < 10) {
        strenghLevel = 1
      } else if (password.nbChar >= 10 && password.hasSpecial) {
        strenghLevel = 3
      } else if (password.nbChar >= 10 || (password.nbChar >= 8 && password.hasSpecial)) {
        strenghLevel = 2
      }
    }
    return strenghLevel
  }

  render() {
    return (
      <Container>
        <Wing color="#161616" justifyContent="flex-end">
          <WingContent>
            <div>
              <Title>Risk Focus</Title>
              <Baseline>{this.props.baseline}</Baseline>
              <div style={{ color: 'white', fontSize: '14px', marginTop: '60px' }}>
                <p>
                  Your password <strong>must</strong> contains at least:
                </p>
                <div>
                  <FlexDiv>
                    {this.state.password.nbChar >= 8 ? (
                      <CheckBox style={{ color: this.props.theme.palette.primary.main }} />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                    &nbsp; eight characters ({this.state.password.nbChar}
                    /8)
                  </FlexDiv>
                  <FlexDiv>
                    {this.state.password.hasUpper ? (
                      <CheckBox style={{ color: this.props.theme.palette.primary.main }} />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                    &nbsp; ONE UPPERCASE LETTER
                  </FlexDiv>
                  <FlexDiv>
                    {this.state.password.hasLower ? (
                      <CheckBox style={{ color: this.props.theme.palette.primary.main }} />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                    &nbsp; one lowercase letter
                  </FlexDiv>
                  <FlexDiv>
                    {this.state.password.hasNumber ? (
                      <CheckBox style={{ color: this.props.theme.palette.primary.main }} />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                    &nbsp; one number
                  </FlexDiv>
                </div>
                <p>We highly recommend at least :</p>
                <FlexDiv>
                  {this.state.password.nbChar >= 10 ? (
                    <CheckBox style={{ color: this.props.theme.palette.primary.main }} />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                  &nbsp; {`ten characters (${this.state.password.nbChar}/10)`}
                </FlexDiv>
                <FlexDiv>
                  {this.state.password.hasSpecial ? (
                    <CheckBox style={{ color: this.props.theme.palette.primary.main }} />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                  &nbsp; one spec!al char@cters, such as:&nbsp;
                  <strong style={{ fontStyle: '16px' }}>!@#&$%[_]</strong>
                </FlexDiv>
              </div>
            </div>
            <div style={leftFooterStyle}>
              <div>
                <a href="https://risk-focus.com" target="_blank" rel="noopener noreferrer">
                  <Button color="primary">About</Button>
                </a>
                <a href="mailto:contact@risk-focus.com">
                  <Button color="primary">Contact</Button>
                </a>
              </div>
              <img src={logo} width="80px" alt="logo" />
            </div>
          </WingContent>
        </Wing>
        <Wing>
          <WingContent justifyContent="center">
            <NewPasswordForm
              onSubmit={this.props.onSubmit}
              passwordCheck={this.handlePasswordChange}
              passwordStrengthLevel={() => this.calculatePasswordStrength(this.state.password)}
            />
          </WingContent>
        </Wing>
      </Container>
    )
  }
}

PasswordPage.propTypes = {
  baseline: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default withTheme()(PasswordPage)
