import React from 'react'
import { Bloc } from 'components/common'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formatsToIsoDate } from 'utils/formatter'

class NotificationBloc extends React.PureComponent {
  generateNotificationRow = data =>
    data.map(notif => {
      const el = notif.uncertainty
      return (
        <tr>
          <td style={{ width: '140px' }}>
            <StyledLink to={`uncertainties/${el.id}/details`}>{el.reference}</StyledLink>
          </td>
          <td>{el.title}</td>
          <td style={{ width: '220px' }}>{`${el.user.firstname} ${el.user.lastname}`}</td>
          <td style={{ width: '120px', textAlign: 'right' }}>{formatsToIsoDate(el.created)}</td>
        </tr>
      )
    })

  render() {
    const { title, subtitle, notifications } = this.props
    console.debug(notifications)
    console.debug(notifications.length)
    return (
      <Bloc title={title} contentProps={{ padding: '10px 20px' }}>
        <div style={{ fontSize: '14px', color: '#5c5c5c' }}>
          <span style={{ color: 'black', fontWeight: 500 }}>{notifications.length}</span> {subtitle}
        </div>
        {notifications.length > 0 && (
          <TableStyled>
            <thead />
            <tbody>{this.generateNotificationRow(notifications)}</tbody>
          </TableStyled>
        )}
      </Bloc>
    )
  }
}

const TableStyled = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 13px;
  margin: 1rem 0;
  tbody {
    border: 1px solid rgba(224, 224, 224, 1);
  }
  thead {
    font-weight: 600;
  }
  tr {
    border-color: 'rgba(224, 224, 224, 1)';
    min-height: 50px;
  }
  td,
  th {
    vertical-align: center;
    text-align: left;
    padding: 10px;
  }
  td {
    border: 1px solid rgba(224, 224, 224, 1);
  }
`

const StyledLink = styled(Link)`
  color: ${props => (props.color ? props.color : 'inherit')};
  margin: ${props => (props.margins ? props.margins : '0 1rem')};
  font-weight: 700;
  color: #00dead;
`

export default NotificationBloc
