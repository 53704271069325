import React from 'react'
import { Input, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { FlexDiv } from 'components/common'

const handleSearch = (onSearch, onFields = []) => e => {
  const inputText = e.target.value
  const regexp = new RegExp(inputText, 'i')
  const filter = obj => {
    return inputText && inputText.length > 1
      ? onFields.reduce((result, field) => {
          const fieldValue = obj[field]
          return (result = result || !!(fieldValue && fieldValue.match(regexp)))
        }, false)
      : true
  }
  return onSearch(filter)
}

const SearchBar = props => {
  return (
    <FlexDiv margins={props.margins ? props.margins : '1rem'} maxWidth="35rem">
      <Input
        id="search"
        placeholder={props.placeholder}
        fullWidth
        disableUnderline
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        onChange={handleSearch(props.onSearch, props.onFields)}
      />
    </FlexDiv>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onFields: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
}

export default SearchBar
