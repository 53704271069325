import React from 'react'
import * as Sentry from '@sentry/browser'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ErrorOutline } from '@material-ui/icons'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(err, errInfo) {
    this.setState({ hasError: true }) // Display fallback UI

    Sentry.withScope(scope => {
      Object.keys(errInfo).forEach(key => {
        scope.setExtra(key, errInfo[key])
      })
      console.warn('Sentry about to capture exception')
      Sentry.captureException(err)
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      console.warn('rendering error boundary with error')
      return (
        <div>
          <h1>
            <FormattedMessage id="" key="" defaultMessage="Something went wrong" />
          </h1>
          <div>
            <ErrorOutline color="error" fontSize="large" />
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default injectIntl(ErrorBoundary)
