import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { unregister } from './registerServiceWorker'
import store, { history } from './store'
import App from './App'

import { bindActionCreators } from 'redux'
import { setupAxiosInterceptors } from 'utils/axios'
import { actions } from 'reducers/authenticationReducer'

import { IntlProvider } from 'react-intl'
// import en from 'react-intl/locale-data/en'
import locale_en from 'i18n/en.json'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  })
}
unregister()

const { redirectToLoginWithMessage, credentialsError } = actions
const boundActions = bindActionCreators(
  { redirectToLoginWithMessage, credentialsError },
  store.dispatch
)
setupAxiosInterceptors(
  msg => boundActions.redirectToLoginWithMessage(msg),
  () => boundActions.credentialsError()
)

// addLocaleData(en)

ReactDOM.render(
  <IntlProvider locale="en" messages={locale_en}>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
)
