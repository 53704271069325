import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { DeclareButton } from 'components/common'

export default class ConfirmationPage extends Component {
  render() {
    const Paragraph = styled.div`
      color: #6e6e6e;
      max-width: 22rem;
      font-weight: 400;
      font-size: 1rem;
    `
    return (
      <React.Fragment>
        <div style={{ padding: '3rem' }}>
          <h2>Thank you</h2>
          <Paragraph>
            We confirm that your input has been recorded. We will inform you of the given follow-up
            soon.
            <br />
            <br />
            For any request or query about the process, you can contact your dedicated risk manager:
            <br />
            <strong style={{ fontWeight: 600 }}>contact@risk-focus.com</strong>
          </Paragraph>
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            padding: '3rem',
          }}
        >
          <DeclareButton />
          <div style={{ textAlign: 'end', marginTop: '1rem' }}>
            or{' '}
            <Link style={{ color: '#00DEAD' }} to="/dashboard">
              access your dashboard
            </Link>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
