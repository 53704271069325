import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { connect } from 'react-redux'
import { DeclareButton, FlexDiv } from '../common'
import MyDeclarations from './MyDeclarations'
import MySettings from './MySettings'
import MyTasks from './MyTasks'
import MyRisks from './MyRisks'

class MyDashboard extends React.Component {
  state = {
    tabId: 0,
  }

  tabs = {
    declarations: 0,
    risks: 1,
    tasks: 2,
    settings: 3,
  }

  handleChange = (event, value) => {
    this.setState(state => {
      if (value !== state.tabId) return { ...state, tabId: value }
      else return null
    })
  }

  selectMySettingsTab = () => {
    this.setState((state, props) => {
      try {
        let { tab } = props.location.state
        if (tab && this.tabs[tab] && this.tabs[tab] !== state.tabId)
          return { ...state, tabId: this.tabs[tab] }
      } catch (e) {}
    })
  }

  componentDidMount() {
    this.selectMySettingsTab()
  }

  componentWillReceiveProps() {
    this.selectMySettingsTab()
  }

  render() {
    const { tabId } = this.state
    return (
      <React.Fragment>
        <FlexDiv>
          <FlexDiv>
            <h1 style={{ textTransform: 'capitalize' }}>
              {this.props.firstname} {this.props.lastname}
            </h1>
          </FlexDiv>
          <FlexDiv direction="column" justifyContent="center" align="flex-end">
            <DeclareButton />
          </FlexDiv>
        </FlexDiv>
        <FlexDiv direction="column" margins="3rem 0">
          <Tabs value={tabId} onChange={this.handleChange} indicatorColor="primary">
            <Tab style={{ textTransform: 'none !important' }} label="Uncertainties I declared" />
            <Tab label="Assigned risks" />
            <Tab label="Assigned tasks" />
            <Tab label="My settings" />
          </Tabs>
          {tabId === 0 && <MyDeclarations />}
          {tabId === 1 && <MyRisks />}
          {tabId === 2 && <MyTasks />}
          {tabId === 3 && <MySettings />}
        </FlexDiv>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  firstname: state.auth.user.firstname,
  lastname: state.auth.user.lastname,
})

export default connect(mapStateToProps)(MyDashboard)
