import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { teal } from '@material-ui/core/colors'

const GlobalStyles = createGlobalStyle`
	* {
	  font-family: 'Open Sans', sans-serif;
	}

  a {
      color:inherit;
      text-decoration: none;
      &:hover {
          cursor: pointer;
      }
  }

  h2 {
      font-weight: 300;
      font-size: 3rem;
  }

  h1 {
      font-size: 3rem;
  }
`

const theme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        height: '36px',
        width: '36px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '13px',
        fontFamily: "'Open Sans', sans-serif",
      },
    },
  },
  palette: {
    primary: {
      light: teal['A200'],
      main: teal['A400'],
      dark: teal['A700'],
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    useNextVariants: true,
  },
})

export default ({ children }) => (
  <div id="layout">
    <CssBaseline />
    <GlobalStyles />
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </div>
)
