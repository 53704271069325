import React, { Component } from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { Block, Check, Email } from '@material-ui/icons'

import { ClickableComponent, SortArrow } from 'components/common'
import { FormattedMessage } from 'react-intl'
import { selectBestUserRole } from 'utils/utils'

const FlexHeaderCell = styled(props => (
  <th {...props}>
    <div>{props.children}</div>
  </th>
))`
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width: ${props => (props.width ? props.width : 'auto')};
  font-size: 14px;
  font-weight: 600;
  div {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`

export default class MembersList extends Component {
  state = {
    dialogs: {
      importResult: false,
      addUser: false,
    },
  }

  calculateLastActionInterval = lastAction => {
    const start = DateTime.fromISO(lastAction)
    return this.lastActionFormat(start.diffNow(['years', 'months', 'weeks', 'days']))
  }

  lastActionFormat = ({ years, months, weeks, days }) => {
    if (years <= -1 || years >= 1) {
      return `${Math.floor(Math.abs(years))} year(s)`
    } else if (months <= -1 || months >= 1) {
      return `${Math.floor(Math.abs(months))} month(s)`
    } else if (weeks <= -1 || weeks >= 1) {
      return `${Math.floor(Math.abs(weeks))} week(s)`
    } else if (days <= -1 || days >= 1) {
      return `${Math.floor(Math.abs(days))} day(s)`
    } else {
      return 'Today'
    }
  }

  generateTableRow = data => {
    if (data && data.length > 0) {
      return data.map((u, index) => (
        <ClickableComponent
          component={TableRow}
          key={index}
          onClick={this.props.onClickRow(u.id)}
          hover
        >
          <TableCell padding="dense" sortDirection={false}>
            {u.lastname}
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            {u.firstname}
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            <FormattedMessage id={`user.role.${selectBestUserRole(u.roles)}`} defaultMessage=" " />
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            {u.lastAction && this.calculateLastActionInterval(u.lastAction)}
          </TableCell>
          <TableCell padding="none" sortDirection={false}>
            {u.enabled && !u.revoked && <Check color="primary" />}
            {!u.enabled && u.revoked && <Block style={{ color: '#e74c3c' }} />}
            {!u.enabled && !u.revoked && <Email style={{ color: '#f39c12' }} />}
          </TableCell>
        </ClickableComponent>
      ))
    } else {
      return <TableRow />
    }
  }

  render() {
    const { data: users, sorted, onSort } = this.props
    return (
      <Table>
        <thead>
          <tr style={{ height: '55px' }}>
            <FlexHeaderCell onClick={onSort('lastname')} width="180px">
              Last Name <SortArrow property="lastname" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell onClick={onSort('firstname')} width="150px">
              First Name <SortArrow property="firstname" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell width="80px" onClick={onSort('role')}>
              Role <SortArrow property="role" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell width="100px" onClick={onSort('lastAction', 'date')}>
              Last action <SortArrow property="lastAction" sorted={sorted} />
            </FlexHeaderCell>
            <FlexHeaderCell padding="none" style={{ width: '40px' }} />
          </tr>
        </thead>
        <TableBody>{this.generateTableRow(users)}</TableBody>
      </Table>
    )
  }
}
