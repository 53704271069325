import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Input, InputLabel, withStyles, MenuItem } from '@material-ui/core'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FlexDiv } from 'components/common'
import { ErrorMessage } from 'components/Staff/common/FormComponents'
import { selectBestUserRole } from 'utils/utils'
import { ReactTelephoneInput } from 'vendors/react-telephone-input'
import flags from 'assets/flags.png'
import { renderFormControlSelect } from 'components/Staff/common/FormComponents2'

const validate = values => {
  const errors = {}
  const requiredFields = ['firstname', 'lastname']

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

class UserDetailForm extends React.Component {
  state = {
    editing: false,
    teams: [],
  }

  editForm = e => {
    if (!this.state.editing) {
      e.preventDefault()
    }
    this.setState(state => ({ editing: !state.editing }))
  }

  handleCancel = form => e => {
    e.preventDefault()
    form.reset()
    this.setState(state => ({ editing: !state.editing }))
  }

  generateTeamItems = teams => {
    return teams.map((t, index) => (
      <MenuItem key={index} value={t.id}>
        {t.name}
      </MenuItem>
    ))
  }

  fieldMargins = '0.2rem 0'

  render() {
    const { onSubmit, initialValues, intl, onRevokeAccess, onGrantAccess } = this.props
    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({
          submitError,
          handleSubmit,
          dirtySinceLastSubmit,
          submitFailed,
          submitting,
          form,
          values,
        }) => {
          // Reset form with current values to reset form "submitFailed"
          // status and remove error message
          if (dirtySinceLastSubmit) form.reset(values)
          return (
            <form onSubmit={handleSubmit} style={{ minHeight: '300px' }}>
              {/* Display error message */}
              {submitFailed && submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <FlexDiv maxWidth="30rem" direction="column" align="start">
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Email address</StyledInputLabel>
                  <Field
                    type="email"
                    name="email"
                    autoComplete="off"
                    disableUnderline
                    inputProps={{ disabled: true }}
                    classes={{
                      root: this.props.classes.textFieldRoot,
                      input: this.props.classes.textFieldInput,
                    }}
                    render={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>First name</StyledInputLabel>
                  <Field
                    name="firstname"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    autoComplete="off"
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    required
                    render={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Last name</StyledInputLabel>
                  <Field
                    name="lastname"
                    autoComplete="off"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    render={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Telephone number</StyledInputLabel>
                  <Field
                    name="landline"
                    autoComplete="off"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    placeholder={this.state.editing ? '+33 9 51 29 33 45' : ''}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldPhoneInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    render={renderPhoneInput}
                  />
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Mobile Number</StyledInputLabel>
                  <Field
                    name="mobile"
                    autoComplete="off"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    placeholder={this.state.editing ? '+33 6 51 29 33 28' : ''}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldPhoneInputEditing,
                          }
                        : {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInput,
                          }
                    }
                    render={renderPhoneInput}
                  />
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Location</StyledInputLabel>
                  <Field
                    name="location"
                    autoComplete="off"
                    disableUnderline
                    inputProps={{ disabled: !this.state.editing }}
                    classes={
                      this.state.editing
                        ? {
                            root: this.props.classes.textFieldRoot,
                            input: this.props.classes.textFieldInputEditing,
                          }
                        : { input: this.props.classes.textFieldInput }
                    }
                    render={renderInput}
                  />
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Role</StyledInputLabel>
                  <Field
                    name="roles"
                    disableUnderline
                    inputProps={{ disabled: true }}
                    classes={{
                      root: this.props.classes.textFieldRoot,
                      input: this.props.classes.textFieldInput,
                    }}
                    render={renderInput}
                    format={v =>
                      intl.formatMessage({
                        id: `user.role.${selectBestUserRole(v)}`,
                        defaultMessage: ' ',
                      })
                    }
                  />
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Team</StyledInputLabel>
                  {(this.props.teams && this.props.teams.length > 0 && (
                    <Field
                      name="team[id]"
                      component={renderFormControlSelect}
                      inputProps={{ disabled: true }}
                      controlProps={{
                        component: 'div',
                        margin: 'dense',
                        fullWidth: true,
                      }}
                      margin="dense"
                      disabled={!this.state.editing}
                    >
                      {this.generateTeamItems(this.props.teams)}
                    </Field>
                  )) || (
                    <Field
                      name="team[id]"
                      disableUnderline
                      inputProps={{ disabled: true }}
                      classes={{
                        root: this.props.classes.textFieldRoot,
                        input: this.props.classes.textFieldInput,
                      }}
                      format={() => `No team ${this.state.editing ? 'assignable' : 'assigned'} yet`}
                      render={renderInput}
                    />
                  )}
                </FlexDiv>
                <FlexDiv margins={this.fieldMargins} align="center">
                  <StyledInputLabel>Last connection</StyledInputLabel>
                  <Field
                    name="lastConnection"
                    disableUnderline
                    inputProps={{ disabled: true }}
                    classes={{
                      root: this.props.classes.textFieldRoot,
                      input: this.props.classes.textFieldInput,
                    }}
                    format={value =>
                      value &&
                      DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
                    }
                    render={renderInput}
                  />
                </FlexDiv>
                <FlexDiv align="start">
                  <StyledButton
                    type="submit"
                    disabled={this.state.editing ? submitting : false}
                    variant="contained"
                    onClick={this.editForm}
                  >
                    {this.state.editing
                      ? intl.formatMessage({ id: 'buttons.label.save' })
                      : intl.formatMessage({ id: 'buttons.label.edit' })}
                  </StyledButton>
                  {this.state.editing ? (
                    <StyledButton
                      type="button"
                      variant="contained"
                      onClick={this.handleCancel(form)}
                    >
                      <FormattedMessage id="buttons.label.cancel" />
                    </StyledButton>
                  ) : initialValues.revoked ? (
                    <StyledButton
                      type="button"
                      variant="contained"
                      width="10rem"
                      onClick={() => onGrantAccess(initialValues.id)}
                    >
                      <FormattedMessage id="buttons.label.access.grant" />
                    </StyledButton>
                  ) : (
                    <StyledButton
                      type="button"
                      variant="contained"
                      width="10rem"
                      disabled={!initialValues.enabled}
                      onClick={() => onRevokeAccess(initialValues.id)}
                    >
                      <FormattedMessage id="buttons.label.access.revoke" />
                    </StyledButton>
                  )}
                </FlexDiv>
              </FlexDiv>
            </form>
          )
        }}
      />
    )
  }
}

const StyledInputLabel = styled(InputLabel)`
  min-width: 10rem;
  width: 10rem;
`

const StyledButton = styled(Button)`
  && {
    width: ${props => (props.width ? props.width : '5rem')};
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0.5rem;
  }
`

const styles = theme => ({
  container: {
    fontSize: '14 !important',
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  inputLabelFocused: {
    color: 'purple',
  },
  inputUnderline: {
    '&:after': {
      backgroundColor: 'purple',
    },
  },
  textFieldRoot: {
    padding: 0,
  },
  textFieldInput: {
    fontSize: 14,
    padding: '11px 13px',
    width: 'calc(20rem - 24px)',
  },
  textFieldInputEditing: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 12px',
    width: 'calc(20rem - 24px)',
    transition: theme.transitions.create(['border-color', 'border-width', 'box-shadow', 'padding']),
    '&:focus': {
      borderWidth: '1px',
      padding: '10px 12px',
      borderColor: theme.palette.primary.light,
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
  },
  textFieldPhoneInputEditing: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: '1rem',
    padding: '10px 12px',
    width: 'calc(20rem - 58px)',
    transition: theme.transitions.create(['border-color', 'border-width', 'box-shadow', 'padding']),
    '&:focus': {
      borderWidth: '1px',
      padding: '10px 12px',
      borderColor: theme.palette.primary.light,
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
  },
  textFieldFormLabel: {
    fontSize: 14,
  },
})

const renderInput = ({ input, meta, ...rest }) => {
  const { touched, error } = meta
  return <Input {...input} {...rest} error={touched && error ? true : false} />
}

const renderPhoneInput = ({ input, meta, inputProps, ...rest }) => {
  const { touched, error } = meta
  const { value, onChange, ...otherInputProps } = input
  return (
    <ReactTelephoneInput
      defaultCountry="fr"
      preferredCountries={['fr', 'us', 'gb']}
      value={value}
      onChange={onChange}
      disabled={inputProps.disabled}
      flagsImagePath={flags}
      inputProps={{
        ...otherInputProps,
        ...rest,
        error: touched && error ? true : false,
        muiInputProps: { ...inputProps },
      }}
    />
  )
}

export default injectIntl(withStyles(styles)(UserDetailForm))
