import React from 'react'
import { Bloc } from 'components/common'
import { Grid } from '@material-ui/core'
import DoughnutChart from 'components/common/Doughnut/DoughnutChart'
import DoughtnutLegend from 'components/common/Doughnut/DoughnutLegend'
import MatrixLegend from './MatrixLegend'
import MatrixChart from './MatrixChart'

export default class OverviewBloc extends React.PureComponent {
  render() {
    const { statistics } = this.props
    return (
      <Bloc title="Overview">
        <Grid container spacing={8} style={{ marginTop: '1rem' }}>
          <Grid item xs={3}>
            <DoughnutChart data={statistics} />
          </Grid>
          <Grid item xs={3}>
            <DoughtnutLegend data={statistics} />
          </Grid>
          <Grid item xs={3}>
            <MatrixChart data={statistics} />
          </Grid>
          <Grid item xs={3}>
            <MatrixLegend data={statistics} />
          </Grid>
        </Grid>
      </Bloc>
    )
  }
}
