import React from 'react'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import { FlexDiv } from 'components/common'

function generateActionDescription({ eventType, content }) {
  let description
  switch (eventType) {
    case 'DECLARATION':
      description = `Declaration of the ${content}`
      break
    case 'STATUS':
      description = `Status changed to "${content}"`
      break
    case 'TASK':
      // Could be just a comment.
      if (content) {
        description = `New task: "${content}"`
      }
      break
    case 'ASSIGNMENT':
      const { firstname, lastname } = JSON.parse(content)
      description = `Uncertainty assigned to ${firstname} ${lastname}`
      break
    default:
      //No description for a comment.
      break
  }
  return description
}

function generateTimelineItems(items) {
  return items
    .slice(0)
    .reverse()
    .map((item, index) => (
      <TimeLineEventBox
        key={index}
        direction="column"
        margins="0.5rem 0"
        align="start"
        style={{ fontSize: '14px' }}
      >
        <FlexDiv className="header" direction="column" align="flex-start">
          <div
            style={{
              color: '#b0b0b0',
            }}
          >
            {DateTime.fromISO(item.created).toLocaleString(DateTime.DATETIME_MED)}
          </div>
          <div
            style={{
              color: '#54698d',
            }}
          >
            {`${item.author.firstname} ${item.author.lastname}`}
          </div>
        </FlexDiv>
        <div className="description">
          {generateActionDescription(item)}
          {item.comment && <CommentBox comment={item.comment} />}
        </div>
      </TimeLineEventBox>
    ))
}

const TimeLineEventBox = styled(FlexDiv)`
  div.header {
    width: 100%;
    font-weight: 600;
    margin-bottom: 5px;
  }
  div.description {
    font-size: 12px;
    color: #6e6e6e;
    width: 100%;
  }
  div.content {
    font-style: italic;
  }
`

const CommentBox = ({ comment }) => (
  <FlexDiv direction="row" margins="2px 0">
    <div className="content">{`"${comment}"`}</div>
  </FlexDiv>
)

export default function({ timeline }) {
  return (
    <div style={{ flex: 1, overflowY: 'auto', overflowX: 'none' }}>
      {generateTimelineItems(timeline)}
    </div>
  )
}
