export const NOOP = null

export const strategies = {
  MITIGATE: 'MITIGATE',
  ACCEPT: 'ACCEPT',
  HEDGE: 'HEDGE',
  REJECT: 'REJECT',
}

export const scoreColors = {
  critical: '#ff5630',
  moderate: '#ffe903',
  low: '#34b37e',
}

export const statusColors = {
  closed: 'rgba(54, 162, 235, 0.5)', //blue
  pending: 'rgba(255, 206, 86, 0.5)', //yellow
  assigned: 'rgba(75, 192, 192, 0.5)', //green
  rejected: 'rgba(255, 99, 132, 0.5)', //red
}

export const uncertaintyStatus = {
  DRAFT: 'DRAFT',
  PENDING_REVIEW: 'PENDING_REVIEW',
  PENDING_ASSIGNMENT: 'PENDING_ASSIGNMENT',
  ASSIGNED: 'ASSIGNED',
  CLOSED: 'CLOSED',
}
