import React from 'react'
import { Field, Form } from 'react-final-form'
import { Checkbox, FormGroup, MenuItem } from '@material-ui/core'
import { FlexDiv, Bloc } from 'components/common'
import {
  renderTextField,
  renderFormControlSelect,
  StyledButton,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormLabel,
} from 'components/Uncertainty/Details/FormComponents'
import { ErrorMessage } from 'components/Staff/common/FormComponents'
import styled from 'styled-components'

const validate = values => {
  const errors = {}
  const requiredFields = ['title', 'description', 'cause', 'suggestion']

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}

const FormButtonWrapper = styled.div`
  padding: 0 10px;
`

class DeclaredRiskForm extends React.Component {
  state = {
    impacts: [],
  }

  generateSelectItems = items => {
    return items.map(i => i)
  }

  asDeclaredValueReadOnly = (status, readOnly) => {
    return status !== 'PENDING_REVIEW' || readOnly
  }

  render() {
    const {
      onSubmit,
      initialValues,
      readOnly,
      forConsultation,
      toggleEditMode = () => null,
    } = this.props
    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          invalid,
          dirtySinceLastSubmit,
          submitFailed,
          form,
          values,
        }) => {
          // Reset form with current values to reset form "submitFailed"
          // status and remove error message
          if (dirtySinceLastSubmit) form.reset(values)
          return (
            <form id="declarations_form" name="declarations" onSubmit={handleSubmit}>
              {/* Display error message */}
              {submitFailed && submitError && <ErrorMessage>{submitError}</ErrorMessage>}
              <FlexDiv width="100%" direction="column" align="start">
                <FlexDiv justifyContent="space-around" width="100%">
                  <FlexDiv margins="4px 10px 0" maxWidth={forConsultation ? '280px' : '298px'}>
                    <Field
                      name="title"
                      component={renderTextField}
                      placeholder="Ex: Subcontractor not able to deliver on time"
                      label="Title"
                      margin="dense"
                      multiline={false}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                  <FlexDiv>
                    <FlexDiv margins="0 5px 0 10px">
                      <Field
                        name="responsability"
                        component={renderFormControlSelect}
                        label="Project Package"
                        controlProps={{
                          component: 'div',
                          margin: 'dense',
                          fullWidth: true,
                        }}
                        margin="dense"
                        disabled={readOnly}
                      >
                        <MenuItem value="hr">Human Resources</MenuItem>
                        <MenuItem value="lc">Logistics</MenuItem>
                        <MenuItem value="mk">Marketing</MenuItem>
                        <MenuItem value="ac">Accounting</MenuItem>
                      </Field>
                    </FlexDiv>
                    <FlexDiv margins="0 10px 0 5px">
                      <Field
                        name="type"
                        component={renderFormControlSelect}
                        label="Type"
                        controlProps={{
                          component: 'div',
                          margin: 'dense',
                          fullWidth: true,
                        }}
                        disabled
                        margin="dense"
                      >
                        <MenuItem value="risk">Risk</MenuItem>
                        <MenuItem value="opportunity">Opportunity</MenuItem>
                      </Field>
                    </FlexDiv>
                  </FlexDiv>
                  <FlexDiv margins="0 2px 0 0" />
                </FlexDiv>
                <FlexDiv fullWidth={true}>
                  <FlexDiv margins="0 10px">
                    <Field
                      name="description"
                      component={renderTextField}
                      placeholder="Explain the risk in your own words"
                      rows="6"
                      rowsMax="7"
                      label="Description"
                      margin="dense"
                      multiline={true}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                  <FlexDiv margins="0 10px">
                    <Field
                      name="cause"
                      component={renderTextField}
                      placeholder=""
                      rows="6"
                      rowsMax="7"
                      label="Cause"
                      margin="dense"
                      multiline={true}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                  <FlexDiv margins="0 10px">
                    <Field
                      name="suggestion"
                      component={renderTextField}
                      placeholder=""
                      rows="6"
                      rowsMax="7"
                      label="Proposed actions for mitigation"
                      margin="dense"
                      multiline={true}
                      disabled={readOnly}
                    />
                  </FlexDiv>
                </FlexDiv>

                <FlexDiv margins="0.5rem" paddings="0 10px">
                  <StyledFormControl margin="normal" fullWidth>
                    <StyledFormLabel
                      component="label"
                      disabled={readOnly}
                      style={{ marginLeft: '-6px' }}
                    >
                      Impacted objective(s)
                    </StyledFormLabel>
                    <FlexDiv align="center">
                      <FormGroup row={true}>
                        <StyledFormControlLabel
                          control={
                            <Field
                              type="checkbox"
                              name="obTime"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="Finish on time"
                        />
                        <StyledFormControlLabel
                          control={
                            <Field
                              type="checkbox"
                              name="obCosts"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="Within costs"
                        />
                        <StyledFormControlLabel
                          control={
                            <Field
                              type="checkbox"
                              name="obQuality"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="High Quality"
                        />
                        <StyledFormControlLabel
                          control={
                            <Field
                              type="checkbox"
                              name="obGuidelines"
                              color="primary"
                              component={renderCheckbox}
                              disabled={readOnly}
                            />
                          }
                          label="Respect HSE guidelines"
                        />
                      </FormGroup>
                      <FormGroup />
                    </FlexDiv>
                  </StyledFormControl>
                </FlexDiv>
                <FlexDiv fullWidth={true}>
                  <FlexDiv direction="column" align="stretch" width="100%" margins="0 10px">
                    <Bloc title="As Declared">
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="schedule"
                          component={renderFormControlSelect}
                          label="Impact on schedule"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="s1">{'Delay < 1 week'}</MenuItem>
                          <MenuItem value="s2">{'1 week to 1 month'}</MenuItem>
                          <MenuItem value="s3">{'1 month to 3 months'}</MenuItem>
                          <MenuItem value="s4">{'> 3 months'}</MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="costs"
                          component={renderFormControlSelect}
                          label="Impact on cost"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="c1">{'1 M$'}</MenuItem>
                          <MenuItem value="c2">{'1 M$ to 5 M$'}</MenuItem>
                          <MenuItem value="c3">{'5 M$ to 20 M$'}</MenuItem>
                          <MenuItem value="c4">{'> 20 M$'}</MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="performance"
                          component={renderFormControlSelect}
                          label="Impact on performance"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="p1">Negligible</MenuItem>
                          <MenuItem value="p2">Small</MenuItem>
                          <MenuItem value="p3">Substantial</MenuItem>
                          <MenuItem value="p4">Huge</MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="occurence"
                          component={renderFormControlSelect}
                          label="Probability of occurence"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={this.asDeclaredValueReadOnly(initialValues.status, readOnly)}
                        >
                          <MenuItem value="o4">{'Very High (> 60%)'}</MenuItem>
                          <MenuItem value="o3">{'High (30% to 60%)'}</MenuItem>
                          <MenuItem value="o2">{'Medium (5% to 60%)'}</MenuItem>
                          <MenuItem value="o1">{'Low (< 5%)'}</MenuItem>
                        </Field>
                      </FlexDiv>
                    </Bloc>
                  </FlexDiv>
                  <FlexDiv direction="column" align="stretch" width="100%" margins="0 10px">
                    <Bloc title="Current">
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="scheduleCurrent"
                          component={renderFormControlSelect}
                          label="Impact on schedule"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="s1">{'Delay < 1 week'}</MenuItem>
                          <MenuItem value="s2">{'1 week to 1 month'}</MenuItem>
                          <MenuItem value="s3">{'1 month to 3 months'}</MenuItem>
                          <MenuItem value="s4">{'> 3 months'}</MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="costsCurrent"
                          component={renderFormControlSelect}
                          label="Impact on cost"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="c1">{'1 M$'}</MenuItem>
                          <MenuItem value="c2">{'1 M$ to 5 M$'}</MenuItem>
                          <MenuItem value="c3">{'5 M$ to 20 M$'}</MenuItem>
                          <MenuItem value="c4">{'> 20 M$'}</MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 2px 0 0">
                        <Field
                          name="performanceCurrent"
                          component={renderFormControlSelect}
                          label="Impact on performance"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="p1">Negligible</MenuItem>
                          <MenuItem value="p2">Small</MenuItem>
                          <MenuItem value="p3">Substantial</MenuItem>
                          <MenuItem value="p4">Huge</MenuItem>
                        </Field>
                      </FlexDiv>
                      <FlexDiv margins="0 0 0 2px">
                        <Field
                          name="occurenceCurrent"
                          component={renderFormControlSelect}
                          label="Probability of occurence"
                          controlProps={{
                            component: 'div',
                            margin: 'dense',
                            fullWidth: true,
                          }}
                          margin="dense"
                          disabled={readOnly}
                        >
                          <MenuItem value="o4">{'Very High (> 60%)'}</MenuItem>
                          <MenuItem value="o3">{'High (30% to 60%)'}</MenuItem>
                          <MenuItem value="o2">{'Medium (5% to 60%)'}</MenuItem>
                          <MenuItem value="o1">{'Low (< 5%)'}</MenuItem>
                        </Field>
                      </FlexDiv>
                    </Bloc>
                  </FlexDiv>
                  {!forConsultation && (
                    <FlexDiv direction="column" align="stretch" width="100%" margins="0 10px">
                      <Bloc title="Target">
                        <FlexDiv margins="0 2px 0 0">
                          <Field
                            name="scheduleTarget"
                            component={renderFormControlSelect}
                            label="Impact on schedule"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="s1">{'Delay < 1 week'}</MenuItem>
                            <MenuItem value="s2">{'1 week to 1 month'}</MenuItem>
                            <MenuItem value="s3">{'1 month to 3 months'}</MenuItem>
                            <MenuItem value="s4">{'> 3 months'}</MenuItem>
                          </Field>
                        </FlexDiv>
                        <FlexDiv margins="0 0 0 2px">
                          <Field
                            name="costsTarget"
                            component={renderFormControlSelect}
                            label="Impact on cost"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="c1">{'1 M$'}</MenuItem>
                            <MenuItem value="c2">{'1 M$ to 5 M$'}</MenuItem>
                            <MenuItem value="c3">{'5 M$ to 20 M$'}</MenuItem>
                            <MenuItem value="c4">{'> 20 M$'}</MenuItem>
                          </Field>
                        </FlexDiv>
                        <FlexDiv margins="0 2px 0 0">
                          <Field
                            name="performanceTarget"
                            component={renderFormControlSelect}
                            label="Impact on performance"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="p1">Negligible</MenuItem>
                            <MenuItem value="p2">Small</MenuItem>
                            <MenuItem value="p3">Substantial</MenuItem>
                            <MenuItem value="p4">Huge</MenuItem>
                          </Field>
                        </FlexDiv>
                        <FlexDiv margins="0 0 0 2px">
                          <Field
                            name="occurenceTarget"
                            component={renderFormControlSelect}
                            label="Probability of occurence"
                            controlProps={{
                              component: 'div',
                              margin: 'dense',
                              fullWidth: true,
                            }}
                            margin="dense"
                            disabled={readOnly}
                          >
                            <MenuItem value="o4">{'Very High (> 60%)'}</MenuItem>
                            <MenuItem value="o3">{'High (30% to 60%)'}</MenuItem>
                            <MenuItem value="o2">{'Medium (5% to 60%)'}</MenuItem>
                            <MenuItem value="o1">{'Low (< 5%)'}</MenuItem>
                          </Field>
                        </FlexDiv>
                      </Bloc>
                    </FlexDiv>
                  )}
                </FlexDiv>
                <FlexDiv justifyContent="flex-end" paddings="15px 0" fullWidth>
                  {!forConsultation && !readOnly && (
                    <FormButtonWrapper>
                      <StyledButton
                        type="button"
                        disabled={submitting}
                        variant="contained"
                        onClick={toggleEditMode}
                      >
                        Cancel
                      </StyledButton>
                    </FormButtonWrapper>
                  )}
                  {!readOnly && (
                    <FormButtonWrapper>
                      <StyledButton
                        type="submit"
                        disabled={pristine || submitting || invalid}
                        variant="contained"
                        color="primary"
                      >
                        Save changes
                      </StyledButton>
                    </FormButtonWrapper>
                  )}
                  {!forConsultation && readOnly && (
                    <FormButtonWrapper>
                      <StyledButton
                        type="button"
                        disabled={submitting}
                        variant="contained"
                        onClick={toggleEditMode}
                      >
                        Edit
                      </StyledButton>
                    </FormButtonWrapper>
                  )}
                </FlexDiv>
              </FlexDiv>
            </form>
          )
        }}
      />
    )
  }
}

const renderCheckbox = ({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => (
  <Checkbox {...rest} name={name} inputProps={restInput} onChange={onChange} checked={!!value} />
)

export default DeclaredRiskForm
