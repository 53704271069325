import React from 'react'
import TaskForm from './TaskForm'
import CloseUncertaintyForm from './CloseUncertaintyForm'
import { fetchCurrentCompanyTeams, fetchTeamMembers } from 'services/api'

class AssignedFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teamSelected: null,
      teams: [],
      members: [],
    }
    this.fetchTeams()
    this.fetchMembers(this.state.teamId)
  }

  fetchTeams = async userId => {
    // if (!userId) return;
    const teams = (await fetchCurrentCompanyTeams()).data
    this.setState(state => ({
      ...state,
      teams: teams,
    }))
  }

  fetchMembers = async teamId => {
    if (!teamId) return
    const members = (await fetchTeamMembers(teamId)).data
    this.setState(state => ({
      ...state,
      members: members,
    }))
  }

  updateSelectedTeam = teamId => {
    this.setState(state => ({
      ...state,
      teamSelected: teamId,
    }))
    this.fetchMembers(teamId)
  }

  render() {
    return (
      <React.Fragment>
        <TaskForm
          uncertainty={this.props.uncertainty}
          onChangeteam={this.updateSelectedTeam}
          teamSelected={this.state.teamSelected}
          teams={this.state.teams}
          members={this.state.members}
          onUpdateSelectedTeam={this.updateSelectedTeam}
          onSubmit={this.props.onSubmitTask}
        />
        <CloseUncertaintyForm onSubmit={this.props.onCloseUncertainty} />
      </React.Fragment>
    )
  }
}
export default AssignedFormContainer
