import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { actions } from 'reducers/uncertaintiesReducer'
import { Container, SearchBar, withPaging, withSorting } from 'components/common'
import DeclarationsList from './DeclarationsList'
import { deleteUncertainty } from 'services/api'

const SortingDeclarationsList = withSorting(withPaging(DeclarationsList))

class MyDeclarationsPage extends React.PureComponent {
  state = {
    data: [],
  }

  handleSearch = filter => {
    this.setState(state => ({
      ...state,
      data: this.props.uncertainties.filter(filter),
    }))
  }

  handleClickRow = uncertaintyId => event => {
    event.preventDefault()
    this.props.history.push(`/dashboard/uncertainties/${uncertaintyId}`)
  }

  handleEdit = uncertaintyId => event => {
    event.stopPropagation()
    event.preventDefault()
    this.props.history.push(`/declare/${uncertaintyId}`)
  }

  handleDelete = uncertaintyId => event => {
    event.stopPropagation()
    event.preventDefault()
    deleteUncertainty(uncertaintyId).then(() => this.props.fetchUncertainties())
  }

  componentWillReceiveProps(nextProps) {
    this.setState(state => {
      return {
        ...state,
        data: nextProps.uncertainties,
      }
    })
  }

  componentDidMount() {
    this.props.fetchUncertainties()
  }

  render() {
    return (
      <Container id="my_declarations">
        <SearchBar
          onSearch={this.handleSearch}
          onFields={['title', 'status', 'reference']}
          placeholder="Search uncertainties by title, status or reference"
        />
        <SortingDeclarationsList
          initialSort={{ property: 'created', type: 'date', asc: false }}
          data={this.state.data}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          onClickRow={this.handleClickRow}
        />
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    uncertainties: state.uncertainties.data,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(MyDeclarationsPage)
)
