import styled from 'styled-components'
import PropTypes from 'prop-types'
import SearchBar from './SearchBar'
export { Header } from './Header'
export { default as DeclareButton } from './DeclareButton'
export { default as ButtonIcon } from './ButtonIcon'
export { default as Badge } from './Badge'
export { default as withSorting } from './sorting/withSorting'
export { default as SortArrow } from './sorting/SortArrow'
export { default as withPaging } from './withPaging'
export { default as ClickableIcon } from './ClickableIcon'
export { default as ClickableComponent } from './ClickableComponent'
export { Bloc, BlocContent } from './Bloc'
export { SearchBar }

export const BorderedSearchBar = styled(SearchBar)`
  border: 1px solid ${props => (props.borderColor ? props.borderColor : '#bdc3c7')} !important;
  border-radius: 2px !important;
  color: ${props => (props.color ? props.color : 'inherit')} !important;
`

export const Container = styled.div`
  width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'inherit')};
  margin: ${props => (props.margins ? props.margins : '0 auto')};
`

export const Body = styled(Container)`
  display: flex;
  flex: 1;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  padding: ${props => (props.paddings ? props.paddings : '1rem')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '1200px')};
  background: ${props => (props.background ? props.background : '')};
`

const FlexDiv = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : '1')};
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  margin: ${props => (props.margins ? props.margins : '0')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'inherit')};
  height: ${props => (props.height ? props.height : 'auto')};
  align-self: ${props => (props.alignSelf ? props.alignSelf : 'auto')};
  width: ${props => (props.width ? props.width : props.fullWidth ? '100%' : 'auto')};
  background: ${props => (props.background ? props.background : '')};
  padding: ${props => (props.paddings ? props.paddings : '0')};
`

// FlexDiv.defaultProps = {
//   direction: 'row',
//   flex: '1',
//   align: 'center',
//   justifyContent: 'flex-start',
//   margin: '0',
//   maxWidth: 'inherit',
//   height: 'auto',
//   alignSelf: 'auto',
//   width: 'auto',
//   background: '',
//   fullWidth: false,
//   paddings: '0',
// }

FlexDiv.propTypes = {
  direction: PropTypes.string,
  flex: PropTypes.string,
  align: PropTypes.string,
  justifyContent: PropTypes.string,
  margin: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  alignSelf: PropTypes.string,
  width: PropTypes.string,
  background: PropTypes.string,
  fullWidth: PropTypes.bool,
  paddings: PropTypes.string,
}

export { FlexDiv }

export const MainTitle = styled.h1`
  font-size: ${props => {
    switch (props.size) {
      case 'small':
        return '1rem'
      case 'medium':
        return '2rem'
      case 'large':
      default:
        return '3rem'
    }
  }};
`
