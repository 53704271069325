import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'
import { withStyles, Grid, Typography, Select, OutlinedInput, MenuItem } from '@material-ui/core'
import StatBloc from '../StatBloc'
import { FlexDiv } from 'components/common'

const PERIOD_30_DAYS = 1
const PERIOD_90_DAYS = 2
const PERIOD_SELECT = 3

const dateCheckRegex = new RegExp('^\\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$', 'i')

const StatsTab = ({
  classes,
  colors,
  data = {},
  initialItem,
  initialPeriod = PERIOD_30_DAYS,
  items = [],
  handleStatsUpdate = () => null,
  options = {},
  projects = [],
}) => {
  const [period, setPeriod] = useState(initialPeriod)
  const [dateStart, setDateStart] = useState(
    DateTime.local()
      .minus({ days: 30 })
      .toJSDate()
  )
  const [dateEnd, setDateEnd] = useState(DateTime.local().toJSDate())
  const [item, setItem] = useState(initialItem)

  React.useEffect(() => {
    handleStatsUpdate(DateTime.fromJSDate(dateStart), DateTime.fromJSDate(dateEnd), projects)
  }, [period, dateStart, dateEnd, handleStatsUpdate, projects])

  const handleOnChangeItem = evt => {
    setItem(evt.target.value)
  }

  const handleOnChangePeriod = evt => {
    const period = evt.target.value
    let periodDateStart = dateStart
    // eslint-disable-next-line default-case
    switch (period) {
      case 1:
        periodDateStart = DateTime.local()
          .minus({ days: 30 })
          .toJSDate()
        break
      case 2:
        periodDateStart = DateTime.local()
          .minus({ days: 90 })
          .toJSDate()
        break
    }
    setPeriod(period)
    setDateStart(periodDateStart)
    const now = DateTime.local().toJSDate()
    setDateEnd(now)
  }

  const handleDateChange = type => date => {
    if (type && dateCheckRegex.test(date.toISOString().substring(0, 10))) {
      if (type === 'start') {
        setDateStart(date)
        handleStatsUpdate(DateTime.fromJSDate(date), DateTime.fromJSDate(dateEnd), projects)
      } else if (type === 'end') {
        setDateEnd(date)
        handleStatsUpdate(DateTime.fromJSDate(dateStart), DateTime.fromJSDate(date), projects)
      }
    }
  }
  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography>Item</Typography>
            <Select
              value={item}
              onChange={handleOnChangeItem}
              className={classes.select}
              input={
                <OutlinedInput
                  id="item-selector"
                  notched={false}
                  classes={{ input: classes.outlinedInput }}
                />
              }
            >
              {items && items.map(it => <MenuItem value={it.value}>{it.label}</MenuItem>)}
            </Select>
          </Grid>
          <Grid item>
            <Typography>Period</Typography>
            <Select
              value={period}
              onChange={handleOnChangePeriod}
              className={classes.select}
              input={
                <OutlinedInput
                  id="period-selector"
                  notched={false}
                  classes={{ input: classes.outlinedInput }}
                />
              }
            >
              <MenuItem value={PERIOD_30_DAYS}>Last 30 days</MenuItem>
              <MenuItem value={PERIOD_90_DAYS}>Last 90 days</MenuItem>
              <MenuItem value={PERIOD_SELECT}>Select Period</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            {period === PERIOD_SELECT && (
              <Grid container direction="row" spacing={8}>
                <Grid item>
                  <Typography> Date start</Typography>
                  <DatePicker
                    id="period-date-start"
                    customInput={
                      <OutlinedInput notched={false} classes={{ input: classes.outlinedInput }} />
                    }
                    selected={dateStart}
                    onChange={handleDateChange('start')}
                    dateFormat="dd-MM-yyyy"
                    startDate={dateStart}
                    endDate={dateEnd}
                    maxDate={Date.now()}
                  />
                </Grid>
                <Grid item>
                  <Typography> Date end</Typography>
                  <DatePicker
                    id="period-date-end"
                    customInput={
                      <OutlinedInput notched={false} classes={{ input: classes.outlinedInput }} />
                    }
                    selected={dateEnd}
                    onChange={handleDateChange('end')}
                    dateFormat="dd-MM-yyyy"
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    maxDate={Date.now()}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <StatBloc
          values={{
            n: data && data.get && data.get(item).datasets[0].data.reduce((a, c) => a + c),
          }}
          desc={items.filter(it => it.value === item)[0].label}
        />

        <Line options={options} data={data && data.get && data.get(item)} />
      </Grid>
      <Grid item xs={4}>
        <div className={classes.legendContainer}>
          <div className={classes.legendSubContainer}>
            <FlexDiv direction="column" align="flex-start" justifyContent="flex-end">
              {projects.map((project, index) => (
                <LegendItem>
                  <div style={{ ...legendStyles.legendColor, backgroundColor: colors[index] }} />
                  <div style={legendStyles.legendName}>{project.name}</div>
                  {/* <div style={legendStyles.legendCount}>11</div> */}
                </LegendItem>
              ))}
            </FlexDiv>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

const LegendItem = ({ children }) => <FlexDiv margins="10px 0">{children}</FlexDiv>

const legendStyles = {
  legend: {
    margin: '0 1rem',
    fontSize: '13px',
  },
  title: {
    fontWeight: 600,
    margin: '0 0 1rem 0',
  },
  legendColor: {
    minWidth: '20px',
    width: '20px',
    height: '20px',
    marginRight: '30px',
  },
  legendName: {
    minWidth: '110px',
    maxWidth: '110px',
    textAlign: 'left',
  },
  legendCount: {
    minWidth: '30px',
    maxWidth: '30px',
  },
}

const styles = {
  chartContainer: {
    width: '600px',
  },
  outlinedInput: {
    padding: '5px 10px',
    minWidth: 150,
  },
  legendContainer: {
    height: '100%',
    paddingLeft: '20px',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  legendSubContainer: {
    background: 'none',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  select: {
    width: 300,
  },
}

export default withStyles(styles)(StatsTab)
