import React from 'react'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import styled from 'styled-components'
import { FlexDiv } from 'components/common'
import { FormControl } from '@material-ui/core'
import { FormLabel } from '@material-ui/core'
import { FormGroup } from '@material-ui/core'

const FilterDialog = ({ filters, onApply, onCancel, onFilterChange, style }) => {
  return (
    <ArrowBox id="arrow_box">
      <FlexDivBordered
        id="filers_dialog"
        direction="column"
        align="start"
        style={{ ...style }} //extends style to enable hiding component
      >
        <FlexDiv id="filters_selection">
          <StyledTable width="100%">
            <tbody>
              <tr>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Show activity</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.activity.act1}
                            onChange={onFilterChange({ group: 'activity', name: 'act1' })}
                            value="act1"
                          />
                        }
                        label="Last 7 days"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.activity.act2}
                            onChange={onFilterChange({ group: 'activity', name: 'act2' })}
                            value="act2"
                          />
                        }
                        label="Last 30 days"
                      />
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={filters.activity.act3}
                          onChange={onFilterChange({ group: 'activity', name: 'act3' })}
                          value="act3"
                        />
                      }
                      label="From"
                    /> */}
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta1}
                            onChange={onFilterChange({ group: 'status', name: 'sta1' })}
                            value="sta1"
                          />
                        }
                        label="Pending for review"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta2}
                            onChange={onFilterChange({ group: 'status', name: 'sta2' })}
                            value="sta2"
                          />
                        }
                        label="Pending for assignment"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta3}
                            onChange={onFilterChange({ group: 'status', name: 'sta3' })}
                            value="sta3"
                          />
                        }
                        label="Assigned"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta4}
                            onChange={onFilterChange({ group: 'status', name: 'sta4' })}
                            value="sta4"
                          />
                        }
                        label="Closed"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Strategy</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.mitigate}
                            onChange={onFilterChange({
                              group: 'strategy',
                              name: 'mitigate',
                            })}
                            value="mitigate"
                          />
                        }
                        label="Mitigate"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.accept}
                            onChange={onFilterChange({
                              group: 'strategy',
                              name: 'accept',
                            })}
                            value="accept"
                          />
                        }
                        label="Accept"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.strategy.hedge}
                            onChange={onFilterChange({ group: 'strategy', name: 'hedge' })}
                            value="hedge"
                          />
                        }
                        label="Hedge"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Rejected</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.status.sta5}
                            onChange={onFilterChange({ group: 'status', name: 'sta5' })}
                            value="sta5"
                          />
                        }
                        label="Yes"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Impact on schedule</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.schedule.low}
                            onChange={onFilterChange({ group: 'schedule', name: 'low' })}
                            value="low"
                          />
                        }
                        label="Delay < 1 week"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.schedule.medium}
                            onChange={onFilterChange({
                              group: 'schedule',
                              name: 'medium',
                            })}
                            value="medium"
                          />
                        }
                        label="1 week to 1 month"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.schedule.high}
                            onChange={onFilterChange({ group: 'schedule', name: 'high' })}
                            value="high"
                          />
                        }
                        label="1 month to 3 months"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.schedule.vhigh}
                            onChange={onFilterChange({ group: 'schedule', name: 'vhigh' })}
                            value="vhigh"
                          />
                        }
                        label="> 3 months"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Impact on costs</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.costs.low}
                            onChange={onFilterChange({ group: 'costs', name: 'low' })}
                            value="low"
                          />
                        }
                        label="< 1 M€"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.costs.medium}
                            onChange={onFilterChange({ group: 'costs', name: 'medium' })}
                            value="medium"
                          />
                        }
                        label="1 M€ to 5 M€"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.costs.high}
                            onChange={onFilterChange({ group: 'costs', name: 'high' })}
                            value="high"
                          />
                        }
                        label="5 M€ to 20 M€"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.costs.vhigh}
                            onChange={onFilterChange({ group: 'costs', name: 'vhigh' })}
                            value="vhigh"
                          />
                        }
                        label="> 20 M€"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Impact on performance</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.performance.low}
                            onChange={onFilterChange({
                              group: 'performance',
                              name: 'low',
                            })}
                            value="low"
                          />
                        }
                        label="Negligible"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.performance.medium}
                            onChange={onFilterChange({
                              group: 'performance',
                              name: 'medium',
                            })}
                            value="medium"
                          />
                        }
                        label="Small"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.performance.high}
                            onChange={onFilterChange({
                              group: 'performance',
                              name: 'high',
                            })}
                            value="high"
                          />
                        }
                        label="Substantial"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.performance.vhigh}
                            onChange={onFilterChange({
                              group: 'performance',
                              name: 'vhigh',
                            })}
                            value="vhigh"
                          />
                        }
                        label="Huge"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
                <td>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Probability of occurence</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.low}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'low',
                            })}
                            value="low"
                          />
                        }
                        label="Low (<5%)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.medium}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'medium',
                            })}
                            value="medium"
                          />
                        }
                        label="Medium (5% to 60%)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.high}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'high',
                            })}
                            value="high"
                          />
                        }
                        label="High (30% to 60%)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={filters.occurence.vhigh}
                            onChange={onFilterChange({
                              group: 'occurence',
                              name: 'vhigh',
                            })}
                            value="vhigh"
                          />
                        }
                        label="Very High (>60%)"
                      />
                    </FormGroup>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </StyledTable>
        </FlexDiv>
        <FlexDiv id="filters_footer" alignSelf="flex-end">
          <StyledButton color="primary" onClick={onApply}>
            Apply filters
          </StyledButton>
          <StyledButton onClick={onCancel}>Cancel filters</StyledButton>
        </FlexDiv>
      </FlexDivBordered>
    </ArrowBox>
  )
}

const StyledButton = styled(Button)`
  && {
    width: 'auto';
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0.5rem 1rem;
  }
`
const FlexDivBordered = styled(FlexDiv)`
  padding: 1rem;
`
const StyledTable = styled.table`
  border-collapse: collapse;
  td {
    padding: 1rem;
    text-align: left;
    vertical-align: top;
  }
`

const ArrowBox = styled.div`
  position: relative;
  border: 1px solid #bdc3c7;
  border-radius: 2px;

  &::after,
  ::before {
    bottom: 100%;
    left: 5%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-bottom-color: white;
    border-width: 12px;
    margin-left: -12px;
  }
  &::before {
    border-bottom-color: #bdc3c7;
    border-width: 13px;
    margin-left: -13px;
  }
`
export default FilterDialog
