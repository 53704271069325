import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Grid } from '@material-ui/core'
import 'rc-slider/assets/index.css'
import { FlexDiv, MainTitle } from 'components/common'
import { Bloc } from 'components/common/Bloc'
import * as api from 'services/api'
import { TextFieldBordered } from 'components/Uncertainty/Details/FormComponents'
import { DateTime } from 'luxon'
import TaskProgressSlider from './TaskProgressSlider'

class TaskConsultPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      task: {},
      progress: 0,
      comment: { value: '', error: null },
    }
    this.fetchTask.bind(this)
  }

  async fetchTask() {
    const task = (await api.fetchTask(this.props.match.params.id)).data
    this.setState({ task: task, progress: task.progress })
  }

  handleCommentChange = e => {
    this.setState({ comment: { value: e.target.value } })
  }

  handleCancel = e => {
    e.preventDefault()
    this.props.history.goBack() //Can we add a state to tell "My Dashboard" to select tasks tab.
  }

  handleSave = e => {
    e.preventDefault()
    if (this.validateBeforeSave())
      api
        .updateTaskProgress({
          ...this.state.task,
          progress: this.state.progress,
          comment: this.state.comment.value,
        })
        .then(res => {
          this.setState({
            task: res.data,
            progress: res.data.progress,
            comment: { value: '', error: null },
          })
        })
  }

  validateBeforeSave = () => {
    const { comment } = this.state
    if (!comment.value || comment.value.length === 0) {
      this.setState({ comment: { error: 'Comment required' } })
      return false
    }
    return true
  }

  onSliderChange = value => {
    // if (this.state.task.status !== 'DONE' && value > this.state.progress) {
    if (this.state.task.status !== 'DONE') {
      this.setState({ progress: value })
    }
  }

  componentDidMount() {
    this.fetchTask()
  }

  render() {
    const { reference, status, title, duedate, description, uncertainties } = this.state.task
    const readOnly = status === 'DONE'
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div style={{ color: '#666666', fontSize: '14px' }}>
            <StyledLink
              to={{
                pathname: '/dashboard',
                state: { tab: 'tasks' },
              }}
            >
              My dashboard
            </StyledLink>
            {` ${String.fromCharCode(187)} `}
            {reference}: {title}
          </div>
        </Grid>
        <Grid item xs={12}>
          <MainTitle size="medium">
            {reference}: {title}
          </MainTitle>
        </Grid>
        <Grid item xs={8}>
          <Bloc title="Progress" contentProps={{ height: '80px' }}>
            <FlexDiv margins="1rem">
              <TaskProgressSlider progress={this.state.progress} onChange={this.onSliderChange} />
            </FlexDiv>
          </Bloc>
        </Grid>
        <Grid item xs={2}>
          <Bloc title="Due date" contentProps={{ height: '80px' }}>
            {duedate}
          </Bloc>
        </Grid>
        <Grid item xs={2}>
          <Bloc title="Status" contentProps={{ height: '80px' }}>
            {status}
          </Bloc>
        </Grid>
        <Grid item xs={8}>
          <Bloc title="Description" contentProps={{ minHeight: '150px' }}>
            {description}
          </Bloc>
        </Grid>
        <Grid item xs={4}>
          <Bloc title="Uncertainties" contentProps={{ height: '80px' }}>
            {uncertainties &&
              uncertainties.map((uct, index) => {
                return (
                  <StyledLink key={index} to={`/dashboard/uncertainties/${uct.id}`}>
                    {uct.reference}
                  </StyledLink>
                )
              })}
          </Bloc>
        </Grid>
        <Grid item xs={8}>
          <Bloc
            title="Comments"
            contentProps={{ minHeight: '80px', maxHeight: '250px', overflowY: 'auto' }}
          >
            {this.state.task.comments &&
              this.state.task.comments.map((item, index) => {
                return (
                  <TimeLineEventBox
                    key={index}
                    direction="column"
                    margins="0.5rem 0"
                    align="start"
                    style={{ fontSize: '14px' }}
                  >
                    <FlexDiv className="header" direction="row" align="flex-end">
                      <div className="author">
                        {`${item.author.firstname} ${item.author.lastname}`}
                      </div>
                      <div className="timestamp">
                        {DateTime.fromISO(item.created).toLocaleString(DateTime.DATETIME_MED)}
                      </div>
                    </FlexDiv>
                    <div className="description">
                      {item.content && <CommentBox comment={item.content} />}
                    </div>
                  </TimeLineEventBox>
                )
              })}
          </Bloc>
        </Grid>
        <Grid item xs={8}>
          <TextFieldBordered
            placeholder="Add a comment"
            rows="4"
            rowsMax="4"
            margin="dense"
            value={this.state.comment.value}
            onChange={this.handleCommentChange}
            error={this.state.comment.error}
            helperText={this.state.comment.error}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={9}>
          <StyledButton
            type="button"
            color="primary"
            variant="contained"
            disabled={readOnly}
            onClick={this.handleSave}
          >
            Save
          </StyledButton>
          <StyledButton
            type="button"
            variant="contained"
            disabled={readOnly}
            onClick={this.handleCancel}
          >
            Cancel
          </StyledButton>
        </Grid>
      </Grid>
    )
  }
}

const TimeLineEventBox = styled(FlexDiv)`
  div.header {
    width: 100%;
    font-weight: 600;
    margin-bottom: 5px;
    .author {
      color: #54698d;
      margin-right: 8px;
    }
    .timestamp {
      color: #7f8c8d;
      font-size: 12px;
    }
  }
  div.description {
    font-size: 12px;
    color: #6e6e6e;
    width: 100%;
  }
`
const CommentBox = ({ comment }) => (
  <FlexDiv direction="row" margins="2px 0">
    {`${comment}`}
  </FlexDiv>
)

const StyledButton = styled(Button)`
  && {
    width: 5rem;
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
    margin: 0.5rem;
  }
`

const StyledLink = styled(Link)`
  color: #00dead;
  font-weight: 700;
`

export default TaskConsultPage
