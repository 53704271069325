export function calculatePercentageEvolution(n, o) {
  if (n === o) {
    return 0
  }
  return ((n - o) / (o === 0 ? 1 : o)) * 100
}
export function absoluteFloor(val) {
  return Math.floor(Math.abs(val))
}

export function compare({ n, o }, lessIsBetter = false) {
  return n > o ? (lessIsBetter ? -1 : 1) : n < o ? (lessIsBetter ? 1 : -1) : 0
}
