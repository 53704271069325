import React from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  RadioGroup,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core'
import { teal } from '@material-ui/core/colors'
import styled from 'styled-components'
//TODO add proptypes

const inputFontSize = '14px'
const labelFontSize = '16px'
const styles = theme => ({
  controlRoot: {
    width: '100%',
    marginBottom: theme.spacing.unit * 1,
  },
  selectRoot: {
    // width: '100%',
    marginTop: theme.spacing.unit * 0,
  },
  textFieldRoot: {
    // width: '100%',
    'label + &': {
      marginTop: theme.spacing.unit * 1,
    },
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #bdc3c7',
    fontSize: inputFontSize,
    padding: '5px 6px',
    // width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
    },
  },
  textFieldInputError: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.palette.error.main,
    fontSize: inputFontSize,
    padding: '5px 6px',
    // width: 'calc(100% - 12px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  label: {
    fontSize: labelFontSize,
  },
  labelFormControl: {
    position: 'relative',
  },
  labelShrink: {
    transform: 'none', //Prevent text from being shrunk
  },
  inputMultiline: {
    boxSizing: 'border-box',
  },
  multiline: {
    padding: 0,
  },
})

const TextFieldBordered = withStyles(styles)(({ classes, label, error, ...custom }) => {
  return (
    <TextField
      multiline={true}
      label={label}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.textFieldRoot,
          input: error ? classes.textFieldInputError : classes.textFieldInput,
          multiline: classes.multiline,
          inputMultiline: classes.inputMultiline,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          shrink: classes.labelShrink,
          formControl: classes.labelFormControl,
        },
      }}
      FormHelperTextProps={{
        margin: null,
      }}
      classes={{
        root: classes.controlRoot,
      }}
      error={error}
      {...custom}
    />
  )
})

const SelectBordered = withStyles(styles)(({ classes, input, children, ...custom }) => (
  <Select
    {...input}
    onChange={input.onChange}
    children={children}
    classes={{
      root: classes.selectRoot,
    }}
    input={
      <Input
        disableUnderline
        classes={{
          root: classes.textFieldRoot,
          input: classes.textFieldInput,
        }}
      />
    }
    {...custom}
  />
))

const StyledFormControl = styled(FormControl)`
  width: ${props => (props.width ? props.widh : props.fullWidth ? '100%' : 'auto')};
`

export const StyledFormControlLabel = withStyles(styles)(
  ({ classes, children, component, ...rest }) => (
    <FormControlLabel {...rest}>{children}</FormControlLabel>
  )
)

export const StyledButton = styled(Button)`
  && {
    width: ${props => (props.width ? props.width : 'auto')};
    font-weight: 600;
    font-size: 1rem;
    text-transform: none;
  }
`

export const StyledFormLabel = withStyles(styles)(props => (
  <FormLabel classes={{ root: props.classes.label }}>{props.children}</FormLabel>
))

export const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => {
  return (
    <TextFieldBordered
      label={label}
      error={!!(touched && error)}
      helperText={touched && error ? error.toString() : ' '}
      {...input}
      {...custom}
    />
  )
}

export const renderFormControlSelect = ({ children, label, meta, controlProps, ...rest }) => {
  return (
    <StyledFormControl error={!!(meta.touched && meta.error)} {...controlProps}>
      <StyledFormLabel component="label">{label}</StyledFormLabel>
      <SelectBordered meta={meta} {...rest}>
        {children}
      </SelectBordered>
      {/* {!!(meta.touched && meta.error) && ( */}
      <FormHelperText>{!!(meta.touched && meta.error) && meta.error.toString()}</FormHelperText>
      {/* )} */}
    </StyledFormControl>
  )
}

export const FormSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: ${props => (props.margins ? props.margins : '1rem 0')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
`
export const FormSectionTitle = styled.h3`
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  margin: 1rem 0;
`
export const FormSectionDesc = styled.p`
  color: #6e6e6e;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7rem;
`

export const FormSectionLeftSide = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  max-width: 22rem;
  padding: 0 1rem;
`

export const FormSectionRightSide = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  margin-left: 2rem;
  padding: 1.5rem 1rem 0;
`
export const FormTitle = styled.div`
  font-size: 40px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  margin: 1rem 0;
`

export const Paragraph = styled.div`
  max-width: 38rem;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.67;
  letter-spacing: 0.3px;
  color: #333333;
  margin: 0 0.5rem 1.5rem;
`
export const Section = styled.div`
  background-color: ${props => (props.background ? props.background : 'inherit')};
  margin: ${props => (props.margins ? props.margins : '0')};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const RadioBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background-color: white;
  border: 1px solid #34495e;
  width: 18rem;
  margin: 0.5rem;
  padding: 1rem;
  & .description-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  & .description {
    color: #333333;
  }
  & .radio {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-right: 0.5rem;
  }
`
export const renderFormRadioControl = ({
  input,
  label,
  name,
  meta: { touched, error },
  children,
  ...custom
}) => {
  return (
    <FormControl margin="dense" component="fieldset" error={!!(touched && error)} {...custom}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        name={name}
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        {children}
      </RadioGroup>
      <FormHelperText>{touched && error ? error.toString() : ' '}</FormHelperText>
    </FormControl>
  )
}
export const renderRadioGroup = ({ input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    value={input.value}
    onChange={(event, value) => {
      return input.onChange(value)
    }}
  />
)

export const renderCheckbox = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => (
  <Checkbox {...rest} name={name} inputProps={restInput} checked={!!value} onChange={onChange} />
)

export const MenuTitle = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-left: 5px;
  margin-top: 5px;
  ::after {
    display: block;
    content: '';
    border-top: 1px solid grey;
    margin-top: 5px;
    width: calc(100% + 10px);
  }
`
