import React from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { MenuItem } from '@material-ui/core'
import { DateTime } from 'luxon'
import createDecorator from 'final-form-focus'
import { FORM_ERROR } from 'final-form'
import { FlexDiv } from 'components/common'
import {
  renderFormErrorMessage,
  renderFormControlSelect,
  renderTextField,
  StyledButton,
} from 'components/Uncertainty/Details/FormComponents'
import { isObjectEmpty } from 'utils/utils'

const validate = values => {
  const errors = {}
  const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/
  const dueDate = values['duedate']
  if (dueDate) {
    if (!dueDate.match(dateRegex)) {
      errors['duedate'] = 'Invalid format'
    } else if (DateTime.fromFormat(dueDate, 'dd/MM/yyyy') < DateTime.local()) {
      errors['duedate'] = 'Wrong date'
      errors[FORM_ERROR] = 'Date cannot be in the past'
    }
  }
  const requiredFields = ['title', 'duedate', 'assigneeTeam', 'assignee']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  return errors
}

const generateMemberItems = members => {
  return members.map((m, index) => (
    <MenuItem key={m.id} value={m.id}>
      {`${m.firstname} ${m.lastname}`}
    </MenuItem>
  ))
}

const generateTeamItems = teams => {
  return teams.map((t, index) => (
    <MenuItem key={t.id} value={t.id}>
      {t.name}
    </MenuItem>
  ))
}
const validateAndSubmit = submitFn => (values, form) => {
  const errors = validate(values)
  if (isObjectEmpty(errors)) {
    submitFn(values).then(res => form.reset())
  }
  return errors
}

const focusOnError = createDecorator()

const TaskForm = props => {
  return (
    <Form
      decorators={[focusOnError]}
      onSubmit={validateAndSubmit(props.onSubmit)}
      render={({ handleSubmit, submitError, submitting }) => {
        return (
          <form id="timeline_form" name="timeline" onSubmit={handleSubmit}>
            <FlexDiv direction="column" align="center" fullWidth>
              <Field
                name="title"
                component={renderTextField}
                placeholder="Task title"
                label="Add new task"
                margin="dense"
                multiline={false}
              />
              <Field
                name="description"
                component={renderTextField}
                placeholder="Description"
                rows="4"
                rowsMax="4"
                label=""
                margin="dense"
                multiline={true}
              />
              <FlexDiv fullWidth justifyContent="space-between" align="start">
                <FlexDiv margins="0 4px 0 0" flex="1">
                  <Field
                    name="duedate"
                    component={renderTextField}
                    placeholder="21/04/2021"
                    label="Due date"
                    margin="dense"
                    multiline={false}
                  />
                </FlexDiv>
                <FlexDiv margins="0 0 0 4px" flex="2">
                  <Field
                    name="assigneeTeam"
                    label="Assign team"
                    value={props.teamSelected}
                    component={renderFormControlSelect} //change observed through FormSpy (see end of the form)
                    controlProps={{
                      component: 'div',
                      margin: 'dense',
                      fullWidth: true,
                    }}
                    margin="dense"
                  >
                    {props.teams && generateTeamItems(props.teams)}
                  </Field>
                </FlexDiv>
                <FlexDiv margins="0 0 0 4px" flex="2">
                  <Field
                    name="assignee"
                    label="Assign user"
                    component={renderFormControlSelect}
                    controlProps={{
                      component: 'div',
                      margin: 'dense',
                      fullWidth: true,
                    }}
                    margin="dense"
                  >
                    {props.members && generateMemberItems(props.members)}
                  </Field>
                </FlexDiv>
              </FlexDiv>
              <FlexDiv>{submitError && renderFormErrorMessage(submitError)}</FlexDiv>
              <FlexDiv justifyContent="flex-end" margins="0.5rem 0" fullWidth>
                <StyledButton type="submit" variant="contained" disabled={submitting}>
                  Add Task
                </StyledButton>
              </FlexDiv>
            </FlexDiv>
            <FormSpy
              onChange={({ values }) =>
                values.assigneeTeam && values.assigneeTeam !== props.teamSelected
                  ? props.onUpdateSelectedTeam(values.assigneeTeam)
                  : null
              }
              subscription={{ values: true }}
            />
          </form>
        )
      }}
    />
  )
}

export default TaskForm
