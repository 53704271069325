import React from 'react'
import { withRouter } from 'react-router'
import { actions } from 'reducers/uncertaintiesReducer'
import { Container, SearchBar, withPaging, withSorting } from 'components/common'
import DeclarationsList from '../MyDeclarations/DeclarationsList'
import { connect } from 'react-redux'
import { fetchAssignedRisks } from 'services/api'

const SortingDeclarationsList = withSorting(withPaging(DeclarationsList))

class MyRisksPage extends React.Component {
  state = {
    fetchedData: [],
    data: [],
  }

  handleSearch = filter => {
    this.setState(state => ({
      ...state,
      data: this.state.fetchedData.filter(filter),
    }))
  }

  handleClickRow = uncertaintyId => event => {
    event.preventDefault()
    this.props.history.push(`/dashboard/uncertainties/${uncertaintyId}`, { tab: 'risks' })
  }
  componentDidMount() {
    fetchAssignedRisks().then(res => {
      this.setState({ fetchedData: res.data, data: res.data })
    })
  }

  render() {
    return (
      <Container id="my_risks">
        <SearchBar
          onSearch={this.handleSearch}
          onFields={['title', 'status', 'reference']}
          placeholder="Search uncertainties by title, status or reference"
        />
        <SortingDeclarationsList
          initialSort={{ property: 'created', type: 'date', asc: false }}
          data={this.state.data}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          onClickRow={this.handleClickRow}
        />
      </Container>
    )
  }
}

export default withRouter(
  connect(
    null,
    actions
  )(MyRisksPage)
)
