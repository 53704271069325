import React from 'react'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import { Bloc } from 'components/common'
import { actions } from 'reducers/registerDetailsReducer'
import { ReviewForm, AssignmentForm, AssignedFormContainer } from './forms'
import TimelineList from './TimelineList'
import { Grid } from '@material-ui/core'

class TimelineContainer extends React.Component {
  onSubmitReviewForm = values => {
    this.props
      .reviewUncertainty(this.props.uncertainty.id, values)
      .then(() => this.props.fetchTimeline(this.props.uncertainty.id))
  }

  onSubmitAssignmentForm = values => {
    this.props
      .assignUncertainty(this.props.uncertainty.id, values)
      .then(() => this.props.fetchTimeline(this.props.uncertainty.id))
  }

  onSubmitTaskForm = ({ title, description, duedate, assignee }) => {
    const values = { title, description, duedate, assignee: { id: assignee } }
    const formattedDuedate = DateTime.fromFormat(values.duedate, 'dd/MM/yyyy').toISODate()
    return this.props
      .submitTask(this.props.uncertainty.id, {
        ...values,
        duedate: formattedDuedate,
      })
      .then(() => this.props.fetchTimeline(this.props.uncertainty.id))
  }

  onCloseUncertainty = ({ comment }) => {
    return this.props.closeUncertainty(this.props.uncertainty.id, { comment })
  }

  render() {
    return (
      <Bloc contentProps={{ height: '600px', display: 'flex' }}>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            {/* <div> */}
            {this.props.uncertainty.status === 'PENDING_REVIEW' && (
              <ReviewForm onSubmit={this.onSubmitReviewForm} />
            )}
            {this.props.uncertainty.status === 'PENDING_ASSIGNMENT' && (
              <AssignmentForm
                onSubmit={this.onSubmitAssignmentForm}
                onCloseUncertainty={this.onCloseUncertainty}
              />
            )}
            {this.props.uncertainty.status === 'ASSIGNED' && (
              <AssignedFormContainer
                uncertainty={this.props.uncertainty}
                onSubmitTask={this.onSubmitTaskForm}
                onCloseUncertainty={this.onCloseUncertainty}
              />
            )}
            {/* </div> */}
          </Grid>
          <Grid item xs={6} style={{ maxHeight: '100%', overflowY: 'auto' }}>
            <TimelineList timeline={this.props.timeline} />
          </Grid>
        </Grid>
      </Bloc>
    )
  }
}

const mapStateToProps = state => ({
  timeline: state.registerDetails.timeline,
})

export default connect(
  mapStateToProps,
  actions
)(TimelineContainer)
