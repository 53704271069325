const GET_UCTS = 'rgstr/GET_UCTS'
const GET_UCTS_SUCCESS = 'rgstr/GET_UCTS_SUCCESS'
const GET_UCTS_FAILURE = 'rgstr/GET_UCTS_FAILURE'

const UPDATE_FILTERS = 'rgstr/UPDATE_FILTERS'

export const resetFilters = () => ({
  risks: true,
  opportunities: true,
  activity: {
    act1: false,
    act2: false,
    act3: false,
  },
  status: {
    sta1: false,
    sta2: false,
    sta3: false,
    sta4: false,
    sta5: false,
  },
  strategy: {
    mitigate: false,
    accept: false,
    hedge: false,
  },
  schedule: {
    low: false,
    medium: false,
    high: false,
    vhigh: false,
  },
  costs: {
    low: false,
    medium: false,
    high: false,
    vhigh: false,
  },
  performance: {
    low: false,
    medium: false,
    high: false,
    vhigh: false,
  },
  occurence: {
    low: false,
    medium: false,
    high: false,
    vhigh: false,
  },
})

const initialState = {
  data: [],
  filters: { ...resetFilters() },
  loading: false,
}

export default function registerReducer(state = initialState, action) {
  if (action.type.indexOf('rgstr/') !== 0) {
    return state
  }
  switch (action.type) {
    case GET_UCTS:
      return { ...state, loading: true }
    case GET_UCTS_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        loading: false,
      }
    case UPDATE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
        loading: false,
      }
    }
    case GET_UCTS_FAILURE: {
      return { ...state, loading: false }
    }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

function fetchUncertainties(filters) {
  return {
    types: [GET_UCTS, GET_UCTS_SUCCESS, GET_UCTS_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/uncertainties/`, filters),
  }
}

function applyFilters(filters) {
  return dispatch => {
    dispatch({ type: UPDATE_FILTERS, payload: filters })
    dispatch(fetchUncertainties(filters))
  }
}

function restoreDefaultFilters() {
  return (dispatch, getState) => {
    const filters = resetFilters()
    dispatch({ type: UPDATE_FILTERS, payload: filters })
    dispatch(fetchUncertainties(filters))
  }
}

export const actions = {
  fetchUncertainties,
  applyFilters,
  resetFilters,
  restoreDefaultFilters,
}
