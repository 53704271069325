const GET_UCT = 'register/details/GET_UCT'
const GET_UCT_SUCCESS = 'register/details/GET_UCT_SUCCESS'
const GET_UCT_FAILURE = 'register/details/GET_UCT_FAILURE'

const UPDATE_UCT = 'register/details/UPDATE_UCT'
const UPDATE_UCT_SUCCESS = 'register/details/UPDATE_UCT_SUCCESS'
const UPDATE_UCT_FAILURE = 'register/details/UPDATE_UCT_FAILURE'

const GET_TIMELINE = 'register/details/GET_TIMELINE'
const GET_TIMELINE_SUCCESS = 'register/details/GET_TIMELINE_SUCCESS'
const GET_TIMELINE_FAILURE = 'register/details/GET_TIMELINE_FAILURE'

const CREATE_TASK = 'register/details/CREATE_TASK'
const CREATE_TASK_SUCCESS = 'register/details/CREATE_TASK_SUCCESS'
const CREATE_TASK_FAILURE = 'register/details/CREATE_TASK_FAILURE'
const CLOSE_UCT = 'register/details/CLOSE_UCT'
const CLOSE_UCT_SUCCESS = 'register/details/CLOSE_UCT_SUCCESS'
const CLOSE_UCT_FAILURE = 'register/details/CLOSE_UCT_FAILURE'

const initialState = {
  data: [],
  timeline: [],
  loading: true,
}

export default function registerDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UCT:
    case GET_TIMELINE:
    case UPDATE_UCT:
    case CREATE_TASK:
      return {
        ...state,
        loading: true,
      }
    case GET_UCT_SUCCESS:
    case UPDATE_UCT_SUCCESS:
    case CREATE_TASK_SUCCESS:
    case CLOSE_UCT_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        loading: false,
      }
    case GET_TIMELINE_SUCCESS:
      return {
        ...state,
        timeline: action.result.data,
        loading: false,
      }
    case GET_UCT_FAILURE:
    case GET_TIMELINE_FAILURE:
    case CREATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

const ROOT_URL = process.env.REACT_APP_API_URL

function fetchUncertainty(id) {
  return {
    types: [GET_UCT, GET_UCT_SUCCESS, GET_UCT_FAILURE],
    promise: client => client.get(`${ROOT_URL}/manage/uncertainties/${id}`),
  }
}

function changeStatus(id, values) {
  return {
    types: [UPDATE_UCT, UPDATE_UCT_SUCCESS, UPDATE_UCT_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/uncertainties/${id}/status`, values),
  }
}

function reviewUncertainty(id, values) {
  return {
    types: [UPDATE_UCT, UPDATE_UCT_SUCCESS, UPDATE_UCT_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/uncertainties/${id}/review`, values),
  }
}

function updateUncertainty(id, values) {
  return {
    types: [UPDATE_UCT, UPDATE_UCT_SUCCESS, UPDATE_UCT_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/uncertainties/${id}/details`, values),
  }
}

function fetchTimeline(id) {
  return {
    types: [GET_TIMELINE, GET_TIMELINE_SUCCESS, GET_TIMELINE_FAILURE],
    promise: client => client.get(`${ROOT_URL}/manage/uncertainties/${id}/timeline`),
  }
}

function assignUncertainty(id, { strategy, assignedTo, comment }) {
  return {
    types: [UPDATE_UCT, UPDATE_UCT_SUCCESS, UPDATE_UCT_FAILURE],
    promise: client =>
      client.post(`${ROOT_URL}/manage/uncertainties/${id}/assign`, {
        strategy,
        assignedTo,
        comment,
      }),
  }
}

function submitTask(id, values) {
  return {
    types: [CREATE_TASK, CREATE_TASK_SUCCESS, CREATE_TASK_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/uncertainties/${id}/tasks`, values),
  }
}

function closeUncertainty(id, { comment }) {
  return {
    types: [CLOSE_UCT, CLOSE_UCT_SUCCESS, CLOSE_UCT_FAILURE],
    promise: client => client.post(`${ROOT_URL}/manage/uncertainties/${id}/close`, { comment }),
    afterSuccess: () => this.fetchTimeline(id),
  }
}

export const actions = {
  assignUncertainty,
  changeStatus,
  fetchTimeline,
  fetchUncertainty,
  closeUncertainty,
  reviewUncertainty,
  submitTask,
  updateUncertainty,
}
