import React from 'react'
import { TablePagination } from '@material-ui/core'
import { FlexDiv } from 'components/common'

const withPaging = WrappedComponent => {
  return class extends React.Component {
    state = {
      rowsPerPageOptions: [5, 10, 25],
      rowsPerPage: 10,
      page: 0,
    }

    handleChangePage = (ev, page) => {
      this.setState({ page })
    }

    handleChangeRowsPerPage = ev => {
      this.setState({ rowsPerPage: ev.target.value })
    }

    calculateRowsPerPage = totalElements => {
      this.setState(state => {
        const { rowsPerPageOptions: rppo, rowsPerPage } = state
        let i = 0
        for (; i < rppo.length; i++) {
          const filter =
            totalElements > 0 &&
            totalElements < rowsPerPage &&
            rowsPerPage !== rppo[i] &&
            totalElements <= rppo[i]
          if (filter) {
            return { ...state, rowsPerPage: rppo[i] }
          } else {
            return null
          }
        }
      })
    }

    render() {
      const { page, rowsPerPage, rowsPerPageOptions } = this.state
      const { data } = this.props
      return (
        <FlexDiv direction="column" align="start">
          <WrappedComponent
            {...this.props}
            data={data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          />
          <TablePagination
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </FlexDiv>
      )
    }
  }
}

export default withPaging
