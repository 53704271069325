import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { actions } from 'reducers/declareReducer'

import DeclareForm from './DeclareForm'
import ErrorDialog from 'components/common/ErrorDialog'

class DeclarePage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    const uncertaintyId = props.match.params.id
    if (uncertaintyId) {
      this.loading = true
      this.fetchUncertainty(uncertaintyId)
    }
  }
  loading = false
  initialValues = {
    type: 'risk',
    documents: [],
  }

  fetchUncertainty = async id => {
    axios.get(`${process.env.REACT_APP_API_URL}/uncertainties/${id}`).then(res => {
      this.setState(state => ({ ...state, uncertainty: res.data }))
    })
  }

  loadInitialValues = () => {
    //load uncertainties data (consultation) or load default initial values
    return this.state.uncertainty ? this.state.uncertainty : this.initialValues
  }

  isNewUncertainty = () => {
    return this.state.uncertainty ? false : true
  }

  handleSave = () => {
    return this.props.saveUncertainty()
  }

  handleSubmit = values => {
    this.props.declareUncertainty(values)
  }

  handleUpload = file => {
    this.props.uploadDocument(file)
  }

  handleRemoveDocument = (index, doc) => evt => {
    this.props.removeDocument(index, doc)
  }

  render() {
    return (
      <React.Fragment>
        <ErrorDialog error={this.props.error} />
        <DeclareForm
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          onDocumentUpload={this.handleUpload}
          onDocumentRemove={this.handleRemoveDocument}
          initialValues={this.loadInitialValues()}
          isNewUncertainty={this.isNewUncertainty()}
        />
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  uncertainty: state.declare.data,
  error: state.declare.error,
})
export default connect(
  mapStateToProps,
  actions
)(DeclarePage)
