import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { teal } from '@material-ui/core/colors'
import { Body, Container, Header } from 'components/common'
import ErrorBoundary from 'components/common/ErrorBoundary'

const theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: 'rgba(0,0,0,0.75)',
        fontWeight: 400,
      },
    },
    MuiIconButton: {
      root: {
        height: '36px',
        width: '36px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: '13px',
        fontFamily: "'Open Sans', sans-serif",
      },
    },
  },
  palette: {
    primary: {
      light: teal['A200'],
      main: teal['A400'],
      dark: teal['A700'],
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.65)',
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    useNextVariants: true,
  },
})

export default ({ render, ...props }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="1200px">
        <Header currentLocation={window.location.pathname} />
        <ErrorBoundary>
          <Body maxWidth="960px" direction="column">
            {render(props)}
          </Body>
        </ErrorBoundary>
      </Container>
    </MuiThemeProvider>
  )
}
