import React from 'react'
import styled from 'styled-components'
import {
  FormControl,
  Input,
  InputLabel,
  Select,
  withStyles,
  FormHelperText,
} from '@material-ui/core'
import { teal } from '@material-ui/core/colors'
//TODO add proptypes

const inputFontSize = '12px'
const labelFontSize = '14px'
const fieldWidth = '100%'

const styles = theme => ({
  // formControlRoot: {
  //   margin: '1rem 0 0 0'
  // },
  // selectRoot: {
  //   width: '25rem'
  // },
  inputLabel: {
    fontSize: labelFontSize,
  },
  formControlRoot: {
    width: fieldWidth,
    marginBottom: theme.spacing.unit * 1,
  },
  selectRoot: {
    width: fieldWidth,
    // marginTop: theme.spacing.unit * 1
  },
  textFieldRoot: {
    padding: '0',
    'label + &': {
      marginTop: theme.spacing.unit * 1,
    },
  },
  textFieldInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #bdc3c7',
    fontSize: inputFontSize,
    padding: '5px 6px',
    width: `calc(${fieldWidth} - 12px)`,
    marginTop: theme.spacing.unit * 1,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
      // boxShadow: '0 0 0 0.1rem rgba(128, 203, 196,.25)'
    },
    multiline: {
      padding: `${theme.spacing.unit - 1}px 0 ${theme.spacing.unit - 1}px`,
    },
  },
  textFieldInputError: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.palette.error.main,
    fontSize: inputFontSize,
    padding: '5px 6px',
    width: `calc(${fieldWidth} - 12px)`,
    marginTop: theme.spacing.unit * 1,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: teal['A200'],
      // boxShadow: '0 0 0 0.1rem rgba(128, 203, 196,.25)'
    },
    multiline: {
      padding: `${theme.spacing.unit - 1}px 0 ${theme.spacing.unit - 1}px`,
    },
  },
})

const FormTextField = withStyles(styles)(({ classes, label, id, helperText, error, ...input }) => {
  return (
    <FormControl className={classes.formControlRoot} aria-describedby={`${id}-text`} error={error}>
      <InputLabel htmlFor={id} shrink classes={{ root: classes.inputLabel }} error={error}>
        {label}
      </InputLabel>
      <Input
        id={id}
        disableUnderline
        classes={{
          root: classes.textFieldRoot,
          input: error ? classes.textFieldInputError : classes.textFieldInput,
        }}
        error={error}
        {...input}
      />
      <FormHelperText id="name-helper-text" error={error}>
        {helperText}
      </FormHelperText>
    </FormControl>
  )
})

const FormSelect = withStyles(styles)(
  ({ id = 'select', classes, input, label, children, error, helperText, ...custom }) => (
    <FormControl className={classes.formControlRoot} aria-describedby={`${id}-text`} error={error}>
      <InputLabel htmlFor={id} shrink classes={{ root: classes.inputLabel }} error={error}>
        {label}
      </InputLabel>
      <Select
        {...input}
        // onChange={input.onChange}
        children={children}
        classes={{
          root: classes.selectRoot,
        }}
        error={!!error}
        input={
          <Input
            id={id}
            disableUnderline
            classes={{
              root: classes.textFieldRoot,
              input: classes.textFieldInput,
            }}
            error={error}
          />
        }
        {...custom}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
)

export const ErrorMessage = styled.div`
  color: #e74c3c;
  background-color: #ecf0f1;
  box-shadow: 1px 1px 2px #7f8c8d;
  font-weight: 400;
  padding: 1rem;
`

export const renderTextField = ({ id, input, label, meta: { touched, error } }) => (
  <FormTextField
    id={id}
    label={label}
    error={!!(touched && !!error)}
    helperText={touched && !!error ? error.toString() : ''}
    {...input}
  />
)

export const renderSelect = ({ children, id, input, label, meta: { touched, error } }) => (
  <FormSelect
    id={id}
    label={label}
    error={!!(touched && !!error)}
    helperText={!!touched && !!error ? error.toString() : ''}
    {...input}
  >
    {' '}
    {children}
  </FormSelect>
)
