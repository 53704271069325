import React from 'react'
import { FlexDiv } from 'components/common'
import { scoreColors } from 'utils/constants'
import { FormattedMessage } from 'react-intl'

const MatrixLegend = ({ data }) => {
  return (
    <div style={styles.legend}>
      <div style={styles.title}>
        <FormattedMessage id="legend.level.title" />
      </div>
      <LegendItem>
        <div style={{ ...styles.legendColor, backgroundColor: scoreColors.critical }} />
        <div style={styles.legendName}>
          <FormattedMessage id="legend.level.critical" />
        </div>
        <div style={styles.legendCount}>{data.criticalCount}</div>
      </LegendItem>
      <LegendItem>
        <div style={{ ...styles.legendColor, backgroundColor: scoreColors.moderate }} />
        <div style={styles.legendName}>
          <FormattedMessage id="legend.level.moderate" />
        </div>
        <div style={styles.legendCount}>{data.moderateCount}</div>
      </LegendItem>
      <LegendItem>
        <div style={{ ...styles.legendColor, backgroundColor: scoreColors.low }} />
        <div style={styles.legendName}>
          <FormattedMessage id="legend.level.low" />
        </div>
        <div style={styles.legendCount}>{data.lowCount}</div>
      </LegendItem>
    </div>
  )
}

const LegendItem = ({ children }) => <FlexDiv margins="10px 0">{children}</FlexDiv>

const styles = {
  legend: {
    margin: '0 1rem',
    fontSize: '13px',
  },
  title: {
    fontWeight: 600,
    margin: '0 0 1rem 0',
  },
  legendColor: {
    minWidth: '20px',
    width: '20px',
    height: '20px',
    marginRight: '30px',
  },
  legendName: {
    minWidth: '110px',
    maxWidth: '110px',
    textAlign: 'left',
  },
  legendCount: {
    minWidth: '30px',
    maxWidth: '30px',
  },
}

export default MatrixLegend
