import React from 'react'
import { Field, Form } from 'react-final-form'
import { FlexDiv } from 'components/common'
import { renderTextField, StyledButton } from 'components/Uncertainty/Details/FormComponents'

const validate = values => {
  const errors = {}
  const requiredFields = ['comment']
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  return errors
}
const validateAndSubmit = async (values, submitFn) => {
  const errors = validate(values)
  if (Object.keys(errors).length > 0) {
    return errors
  } else return submitFn(values)
}
const CloseUncertaintyForm = props => {
  return (
    <Form
      onSubmit={values => validateAndSubmit(values, props.onSubmit)}
      render={({ handleSubmit, submitting, pristine }) => {
        return (
          <form id="timeline_form" name="timeline" onSubmit={handleSubmit}>
            <FlexDiv direction="column" align="start" fullWidth>
              <Field
                name="comment"
                component={renderTextField}
                placeholder="Add a comment"
                rows="4"
                rowsMax="4"
                label="Close the uncertainty"
                margin="dense"
                multiline={true}
              />
              <FlexDiv justifyContent="flex-end" margins="0.5rem 0" fullWidth>
                <StyledButton type="submit" variant="contained" disabled={pristine || submitting}>
                  Submit
                </StyledButton>
              </FlexDiv>
            </FlexDiv>
          </form>
        )
      }}
    />
  )
}

export default CloseUncertaintyForm
