import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { DateTime } from 'luxon'
import { AddCircleOutlined, CloudUploadOutlined } from '@material-ui/icons'
import { actions } from 'reducers/usersReducer'
import {
  BorderedSearchBar,
  ButtonIcon,
  Container,
  FlexDiv,
  withPaging,
  withSorting,
} from 'components/common'
import AddUserDialog from './AddUserDialog'
import UsersImportResultDialog from './UsersImportResultDialog'
import UsersList from './UsersList'
import { selectBestUserRole } from 'utils/utils'

const SortingUserList = withSorting(withPaging(UsersList))

class UsersSection extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dialogs: {
        importResult: false,
        addUser: false,
      },
      rowsPerPageOptions: [5, 10, 25],
      rowsPerPage: 10,
      page: 0,
      users: this.addUserRolePropery(props.users),
    }
  }

  addUserRolePropery = users => {
    if (users && users.length) {
      users = users.map(user => ({ ...user, role: selectBestUserRole(user.roles) }))
    }
    return users
  }

  openDialog = dialog => {
    this.setState(state => ({ dialogs: { ...state.dialogs, [dialog]: true } }))
  }

  openFileDialog = e => this.upload.click()

  closeDialog = dialog => {
    this.setState(state => ({
      dialogs: { ...state.dialogs, [dialog]: false },
    }))
  }

  closeImportResultDialog = () => {
    this.props.closeImportResultDialog()
  }

  calculateLastActionInterval = lastAction => {
    const start = DateTime.fromISO(lastAction)
    return this.lastActionFormat(start.diffNow(['years', 'months', 'weeks', 'days']))
  }

  lastActionFormat = ({ years, months, weeks, days }) => {
    if (years <= -1 || years >= 1) {
      return `${Math.floor(Math.abs(years))} year(s)`
    } else if (months <= -1 || months >= 1) {
      return `${Math.floor(Math.abs(months))} month(s)`
    } else if (weeks <= -1 || weeks >= 1) {
      return `${Math.floor(Math.abs(weeks))} week(s)`
    } else if (days <= -1 || days >= 1) {
      return `${Math.floor(Math.abs(days))} day(s)`
    } else {
      return 'Today'
    }
  }

  _handleClickRow = id => evt => {
    this.props.history.push(`/users/${id}/details`)
  }

  _handleOnChange = evt => {
    this.props.importUsers(evt.target.files[0])
    evt.target.value = ''
  }

  _handleSearch = filter => {
    this.setState((state, props) => ({
      ...state,
      users: props.users.filter(filter),
    }))
  }

  _handleSubmit = values => this.props.addUser(values)

  componentWillReceiveProps(nextProps) {
    this.setState(state => ({ ...state, users: this.addUserRolePropery(nextProps.users) }))
  }

  render() {
    return (
      <Container id="users">
        {this.state.dialogs.addUser && (
          <AddUserDialog
            open={this.state.dialogs.addUser}
            onClose={() => this.closeDialog('addUser')}
            onSubmit={this._handleSubmit}
            teams={this.props.teams}
            initialValues={{}}
          />
        )}
        {this.props.importResult && (
          <UsersImportResultDialog
            open={this.props.importResult !== null}
            onClose={this.closeImportResultDialog}
            data={this.props.importResult}
          />
        )}
        <FlexDiv justifyContent="space-between" align="center">
          <BorderedSearchBar
            onSearch={this._handleSearch}
            onFields={['firstname', 'lastname', 'email', 'role']}
            placeholder="Search for a user by firstname, lastname..."
          />
          <FlexDiv justifyContent="flex-end" align="center">
            <ButtonIcon icon={AddCircleOutlined} onClick={() => this.openDialog('addUser')}>
              Add User
            </ButtonIcon>
            <ButtonIcon icon={CloudUploadOutlined} onClick={this.openFileDialog}>
              Import Users
            </ButtonIcon>
            <input
              name="document"
              type="file"
              ref={ref => (this.upload = ref)}
              style={{ display: 'none' }}
              accept=".csv,text/csv"
              onChange={this._handleOnChange}
            />
          </FlexDiv>
        </FlexDiv>
        <SortingUserList
          onClickRow={this._handleClickRow}
          initialSort={{ property: 'lastname', type: 'text', asc: true }}
          data={this.state.users}
        />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  importResult: state.users.importResult,
})

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(UsersSection)
)
