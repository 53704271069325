import React from 'react'
import { Body, Container, Header } from 'components/common'
import ErrorBoundary from 'components/common/ErrorBoundary'

export default ({ render, ...props }) => {
  return (
    <Container maxWidth="1200px">
      <Header currentLocation={window.location.pathname} />
      <ErrorBoundary>
        <Body direction="column">{render(props)}</Body>
      </ErrorBoundary>
    </Container>
  )
}
