import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

// import { FormattedMessage } from 'react-intl'

const UncertaintyLockedDialog = ({ classes, error, onCancel = () => null }) => {
  const [isOpen, setOpen] = React.useState(!!error && error.response)
  React.useEffect(() => setOpen(!!error && error.response), [error])

  const handleClose = e => {
    e && e.stopPropagation()
    setOpen(false)
    onCancel()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="uct-lock-dialog-title"
      aria-describedby="uct-lock-dialog-description"
      fullWidth={true}
      maxWidth="md"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onBackdropClick={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle id="uct-lock-dialog-title">Uncertainty locked</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {/* { <FormattedMessage id={error.response.data.code} /> } */}
          {isOpen && `This uncertainty is currently being edited by ${error.response.data.message}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(UncertaintyLockedDialog)
