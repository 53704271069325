import React from 'react'
import { Field, Form } from 'react-final-form'
import { MenuItem } from '@material-ui/core'
import { FlexDiv } from 'components/common'
import {
  renderTextField,
  renderFormControlSelect,
  StyledButton,
} from 'components/Uncertainty/Details/FormComponents'

const validate = values => {
  const errors = {}
  if (
    values['status'] === 'DRAFT' ||
    values['status'] === 'REJECTED' ||
    values['status'] === 'CLOSED'
  ) {
    if (!values['comment']) {
      errors['comment'] = 'Required'
    }
  }
  if (!values['status']) {
    errors['status'] = 'Required'
  }
  return errors
}

class ReviewForm extends React.Component {
  generateSelectItems = items => {
    return items.map(i => i)
  }

  validateAndSubmit = submitFn => values => {
    const errors = validate(values)
    if (Object.keys(errors).length > 0) {
      return errors
    } else return submitFn(values)
  }

  render() {
    return (
      <Form
        onSubmit={this.validateAndSubmit(this.props.onSubmit)}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form id="timeline_form" name="timeline" onSubmit={handleSubmit}>
              <FlexDiv direction="column" align="start" fullWidth>
                <Field
                  name="status"
                  component={renderFormControlSelect}
                  label="Select status"
                  controlProps={{
                    component: 'div',
                    margin: 'dense',
                    fullWidth: true,
                  }}
                  margin="dense"
                >
                  <MenuItem value="DRAFT">Draft</MenuItem>
                  <MenuItem value="PENDING_ASSIGNMENT">Pending for assignment</MenuItem>
                  <MenuItem value="REJECTED">Rejected</MenuItem>
                  <MenuItem value="CLOSED">Close</MenuItem>
                </Field>

                <Field
                  name="comment"
                  component={renderTextField}
                  placeholder="Add a comment"
                  rows="4"
                  rowsMax="4"
                  label=""
                  margin="dense"
                  multiline={true}
                />
                <FlexDiv justifyContent="flex-end" margins="0.5rem 0" fullWidth>
                  <StyledButton type="submit" disabled={pristine || submitting} variant="contained">
                    Submit
                  </StyledButton>
                </FlexDiv>
              </FlexDiv>
            </form>
          )
        }}
      />
    )
  }
}

export default ReviewForm
