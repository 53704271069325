import React from 'react'
import { withRouter } from 'react-router'
import { DateTime } from 'luxon'
import { Badge, Bloc, ClickableComponent, FlexDiv } from 'components/common'
import { Grid } from '@material-ui/core'

class DedicatedTasksContainer extends React.PureComponent {
  openDialog = () => {
    return null
  }

  handleClickItem = id => e => {
    e.preventDefault()
    e.stopPropagation()
    this.props.history.push(`/dashboard/tasks/${id}`)
  }
  generateTaskList = tasks => {
    if (!tasks || tasks.length === 0) {
      return <div>No tasks available</div>
    }
    return tasks.reverse().map((task, index) => (
      <FlexDiv
        key={index}
        direction="column"
        align="stretch"
        style={{
          border: '1px solid aquablue',
          color: '#6e6e6e',
          fontSize: '13px',
          padding: '10px 0',
        }}
        maxWidth="100%"
        fullWidth={true}
      >
        <ClickableComponent
          component={FlexDiv}
          alignSelf="stretch"
          onClick={this.handleClickItem(task.id)}
        >
          <FlexDiv justifyContent="flex-start">
            <span
              style={{ color: '#54698d', fontSize: '14px', fontWeight: 600, minWidth: '120px' }}
            >
              {task.reference}
            </span>
            <span>
              <strong>Assigned to : </strong>
              {task.assignee &&
                `${task.assignee.team.name} (${task.assignee.firstname.charAt(0)}. ${
                  task.assignee.lastname
                })`}
            </span>
          </FlexDiv>
          <FlexDiv style={{ padding: '0 10px' }} justifyContent="flex-end">
            <span>
              <strong>Due date : </strong>
              {DateTime.fromISO(task.duedate).toISODate()}
            </span>
          </FlexDiv>
          <Badge backgroundColor="rgb(29, 233, 182)">{task.status}</Badge>
        </ClickableComponent>
        <FlexDiv direction="column" align="start">
          <div style={{ color: '#333333', fontWeight: 600 }}>{task.title}</div>
          <div>{task.description}</div>
        </FlexDiv>
        <FlexDiv
          direction="row"
          align="center"
          justifyContent="space-between"
          style={{ fontSize: '13px' }}
          width="80%"
          margins="5px 0"
        />
      </FlexDiv>
    ))
  }

  render() {
    return (
      <Bloc contentProps={{ height: '348px', overflowY: 'auto' }}>
        <Grid container spacing={16}>
          <Grid item xs={10}>
            {this.generateTaskList(this.props.data)}
          </Grid>
        </Grid>
      </Bloc>
    )
  }
}

export default withRouter(DedicatedTasksContainer)
