import React from 'react'
import { DateTime } from 'luxon'
import { Bloc } from 'components/common'
import { fetchManagerNotifications, fetchKPIOverview, fetchKPIDetailed } from 'services/api'

import OverviewBloc from './OverviewBloc'
import NotificationBloc from './NotificationBloc'
import DetailedStatistics from './DetailedStatistics'

class DashboardPage extends React.Component {
  state = {
    reviewNotifications: [],
    assignmentNotifications: [],
    notifications: [],
    statistics: { overview: {}, detailed: {} },
  }

  fetchStatistics = async () => {
    let statsOverview = {}
    let statsDetailed = {}
    try {
      statsOverview = (await fetchKPIOverview()).data
    } catch (err) {
      console.error(err)
    }
    try {
      statsDetailed = (await fetchKPIDetailed(
        DateTime.local()
          .minus({ days: 30 })
          .toISO({ suppressMilliseconds: true }),
        DateTime.local().toISO({ suppressMilliseconds: true })
      )).data
    } catch (err) {
      console.error(err)
    }
    this.setState(state => ({
      ...state,
      statistics: { ...state.statistics, overview: statsOverview, detailed: statsDetailed },
    }))
  }

  fetchStatsDetailed = async (dateStart, dateEnd) => {
    try {
      dateStart = DateTime.fromJSDate(dateStart).toISO({ suppressMilliseconds: true })
      dateEnd = DateTime.fromJSDate(dateEnd).toISO({ suppressMilliseconds: true })
      const statsDetailed = (await fetchKPIDetailed(dateStart, dateEnd)).data
      this.setState(state => ({
        ...state,
        statistics: { ...state.statistics, detailed: statsDetailed },
      }))
    } catch (err) {
      console.error(err)
    }
  }

  fetchNotifications = async () => {
    const reviewNotifications = (await fetchManagerNotifications('pending_review')).data
    const assignmentNotifications = (await fetchManagerNotifications('pending_assignment')).data
    this.setState(state => ({
      ...state,
      reviewNotifications: reviewNotifications,
      assignmentNotifications: assignmentNotifications,
    }))
  }

  componentDidMount() {
    this.fetchNotifications()
    this.fetchStatistics()
  }

  render() {
    const { statistics, reviewNotifications, assignmentNotifications } = this.state

    return (
      <React.Fragment>
        <OverviewBloc statistics={statistics.overview} />
        <Bloc title="Details">
          <DetailedStatistics
            statistics={statistics}
            initialPeriod={1}
            handleStatsUpdate={this.fetchStatsDetailed}
          />
        </Bloc>
        <NotificationBloc
          title="Pending review"
          subtitle={' uncertainties are pending for review'}
          notifications={reviewNotifications}
        />
        <NotificationBloc
          title="Pending assignment"
          subtitle={' uncertainties are pending for assignment'}
          notifications={assignmentNotifications}
        />
      </React.Fragment>
    )
  }
}

export default DashboardPage
