import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp, CloudDownloadOutlined } from '@material-ui/icons'
import { SearchBar, withPaging, withSorting, Bloc, ButtonIcon } from 'components/common'
import { actions } from 'reducers/registerReducer'
import FilterDialog from './FiltersDialog'
import UncertaintiesList from './UncertaintiesList'
import { exportUncertainties } from 'services/api'
import fileDownload from 'js-file-download'

const SortingUncertaintiesList = withSorting(withPaging(UncertaintiesList))

class RegisterPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      originalFilters: props.filters,
      filters: props.filters,
      data: props.data,
      displayFilters: false,
    }
    props.fetchUncertainties(props.filters)
    if (props.location.state && props.location.state.fromMenu) {
      props.restoreDefaultFilters()
    }
  }

  onCheckboxChange = checkbox => {
    this.setState(
      state => ({
        ...state,
        filters: {
          ...state.filters,
          [checkbox]: !state.filters[checkbox],
        },
      }),
      () => {
        this.props.applyFilters(this.state.filters)
      }
    )
  }

  toggleFilters = () => {
    this.setState(state => ({
      ...state,
      displayFilters: !state.displayFilters,
    }))
  }

  handleFilterChange = filter => evt => {
    this.setState(state => ({
      ...state,
      filters: {
        ...state.filters,
        [filter.group]: {
          ...state.filters[filter.group],
          [filter.name]: !state.filters[filter.group][filter.name],
        },
      },
    }))
  }

  onApplyFilters = filters => {
    this.props.applyFilters(filters)
  }

  onCancelFilters = () => {
    this.props.restoreDefaultFilters()
  }

  onFilterDialogClose = () => {
    this.setState(state => ({ ...state, displayFilters: false }))
  }

  handleSearch = filter => {
    this.setState((state, props) => ({
      ...state,
      data: props.data.filter(filter),
    }))
  }

  handleExport = () => {
    exportUncertainties(this.state.filters).then(res => {
      const defaultFileName = 'export.zip'
      const responseFileName = res.headers['x-filename']
      const fileName = responseFileName ? responseFileName : defaultFileName
      fileDownload(res.data, fileName, 'application/zip')
    })
  }

  componentWillReceiveProps(nProps) {
    this.setState(state => ({
      ...state,
      filters: nProps.filters,
      data: nProps.data,
    }))
  }

  render() {
    const { filters } = this.state
    return (
      <Bloc id="bloc" contentProps={{ padding: '1rem' }}>
        <StyledTable>
          <tbody>
            <tr>
              <td>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={filters.risks}
                        onChange={() => this.onCheckboxChange('risks')}
                        value="risks"
                      />
                    }
                    label="Risks"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={filters.opportunities}
                        onChange={() => this.onCheckboxChange('opportunities')}
                        value="opportunities"
                      />
                    }
                    label="Opportunities"
                  />
                </div>
              </td>
              <td onClick={this.toggleFilters}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {this.state.displayFilters ? <ArrowDropUp /> : <ArrowDropDown />}
                  Filters options
                </div>
              </td>
              <td>
                <SearchBar
                  onSearch={this.handleSearch}
                  onFields={['title', 'reference', 'status', 'level']}
                  placeholder="Find uncertainties by keywords, reference, user, ..."
                  margins="5px"
                />
              </td>
              <ButtonIcon icon={CloudDownloadOutlined} onClick={this.handleExport}>
                Export
              </ButtonIcon>
            </tr>
          </tbody>
        </StyledTable>
        {/* Rendering FilterDialog is expensive and useless if not displayed */}
        {this.state.displayFilters && (
          <FilterDialog
            filters={filters}
            onFilterChange={this.handleFilterChange}
            onCancel={() => {
              this.onCancelFilters()
              this.toggleFilters()
            }}
            onApply={() => {
              this.onApplyFilters(filters)
              this.toggleFilters()
            }}
          />
        )}
        <SortingUncertaintiesList
          initialSort={{ property: 'created', type: 'date', asc: true }}
          data={this.state.data}
        />
      </Bloc>
    )
  }
}

const StyledTable = styled.table`
  width: 100%;
  margin: 1rem auto;
  padding: 0;
  border-collapse: collapse;
  max-height: 30px;
  font-size: 14px;
  td:first-child {
    width: 13rem;
    text-align: center;
    border: none;
  }
  td:nth-child(2) {
    width: 10rem;
    cursor: pointer;
    text-align: start;
  }
  td {
    border: 1px solid #bdc3c7;
    vertical-align: middle;
    text-align: center;
    padding: 0;
  }
`

const mapStateToProps = state => ({
  filters: state.register.filters,
  data: state.register.data,
})

export default connect(
  mapStateToProps,
  actions
)(RegisterPage)
