import React from 'react'
import styled from 'styled-components'
import fileDownload from 'js-file-download'
import { Grid } from '@material-ui/core'

import * as api from 'services/api'
import { Bloc } from 'components/common'
import DocumentList from 'components/Uncertainty/Declare/DocumentList'
import DeclaredRiskForm from './DeclaredRiskForm'

class DeclaredRiskContainer extends React.Component {
  onSubmit = values => {
    this.props.onSubmit(values)
  }

  handleDocumentOpen(documentId) {
    return function(evt) {
      evt.preventDefault()
      api.downloadDocument(documentId).then(res => {
        const fileName = res.headers['x-filename']
        fileDownload(res.data, fileName, 'application/zip')
      })
    }
  }

  render() {
    const { user, assignedTo } = this.props.data
    return (
      <React.Fragment>
        <Bloc contentProps={{ height: '730px' }}>
          <DeclaredRiskForm
            toggleEditMode={this.props.toggleEditMode}
            onSubmit={this.onSubmit}
            initialValues={this.props.data}
            readOnly={this.props.readOnly}
            forConsultation={this.props.forConsultation}
          />
        </Bloc>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Bloc
              title="Informations"
              contentProps={{
                height: '238px',
                overflowY: 'auto',
                fontSize: '16px',
                padding: '20px',
              }}
            >
              <div style={{ color: '#6e6e6e' }}>Author:</div>
              <div style={{ color: '#00dead', fontWeight: '600' }}>
                {user && user.firstname} {user && user.lastname}
                {user && user.team && ', ' + user.team.name}
              </div>
              <div style={{ color: '#6e6e6e', marginTop: '1rem' }}>Uncertainty assigned to:</div>
              <div style={{ color: 'inherit', fontWeight: '600' }}>
                {(assignedTo &&
                  assignedTo.team &&
                  `${assignedTo.team.name} 
                    (${assignedTo.firstname} ${assignedTo.lastname})`) ||
                  'Unassigned'}
              </div>
            </Bloc>
          </Grid>
          <Grid item xs={6}>
            <Bloc
              title="Documents"
              contentProps={{ height: '238px', overflowY: 'auto' }}
              style={{ flex: 1 }}
            >
              <DocumentListStyled
                documents={this.props.data.documents}
                onClick={this.handleDocumentOpen}
                dense={true}
                hoverable={true}
              />
            </Bloc>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export const BlocContent = styled.div`
  background-color: white;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
`

const DocumentListStyled = styled(DocumentList)`
  font-size: 12px !important;
`

export default DeclaredRiskContainer
