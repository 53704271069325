import React from 'react'
import { connect } from 'react-redux'
import { Field, formValues, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  MenuItem,
  Radio,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { animateScroll as scroll } from 'react-scroll'
import { FormattedMessage, injectIntl } from 'react-intl'

import DocumentList from './DocumentList'
import { FlexDiv } from 'components/common'
import {
  renderCheckbox,
  renderFormRadioControl,
  renderRadioGroup,
  renderTextField,
  renderFormControlSelect,
  StyledFormControlLabel,
  StyledFormLabel,
  FormSection,
  FormSectionDesc,
  FormSectionLeftSide,
  FormSectionRightSide,
  FormSectionTitle,
  FormTitle,
  Paragraph,
  RadioBox,
  Section,
  MenuTitle,
} from './common/FormComponents'

const validate = values => {
  const errors = {}
  /** Field position in array is important for the scrollToTop function */
  const requiredFields = [
    'type',
    'responsability',
    'topic',
    'description',
    'title',
    'cause',
    'occurence',
    'schedule',
    'costs',
    'performance',
    'suggestion',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  if (!values.obCosts && !values.obGuidelines && !values.obQuality && !values.obTime) {
    errors.objectives = 'Required'
  }
  return errors
}

const FORM_NAME = 'DeclareForm'

class DeclareForm extends React.Component {
  _handleSave = e => {
    e.preventDefault()
    this.props.onSave().catch(err => {
      if (err && err instanceof SubmissionError) scrollToFirstFieldInError(err.errors)
    })
  }

  _openFileDialog = e => this.upload.click()

  render() {
    const { handleSubmit, submitting, intl } = this.props
    if (this.props.initialValues)
      return (
        <form
          style={{ display: 'flex', flexFlow: 'column' }}
          onSubmit={handleSubmit}
          initialvalues={this.props.initialValues}
        >
          <Section background="#ecf0f1">
            <FormTitle>Declare a new uncertainty</FormTitle>
            <div>
              <Paragraph>
                Identifying and understanding the uncertainties that could alter the organization is
                the first step to Risk Management and to the implementation of a successful long
                term strategy.
              </Paragraph>
              <div style={{ fontWeight: 600, margin: '0.5rem' }}>
                What type of uncertainty would you like to declare ?
              </div>
              <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                <RadioBox>
                  <div className="radio">
                    <Field component={renderRadioGroup} name="type" aria-label="type">
                      <Radio
                        color="primary"
                        value="risk"
                        aria-label="risk"
                        disabled={!this.props.isNewUncertainty}
                      />
                    </Field>
                  </div>
                  <div className="description-wrapper">
                    <span style={{ fontWeight: '600' }}>A risk</span>
                    <div className="description">
                      It may induces a negative impact on the business
                    </div>
                  </div>
                </RadioBox>
                <RadioBox>
                  <div className="radio">
                    <Field component={renderRadioGroup} name="type" aria-label="type">
                      <Radio
                        color="primary"
                        value="opportunity"
                        aria-label="opportunity"
                        disabled={!this.props.isNewUncertainty}
                      />
                    </Field>
                  </div>
                  <div className="description-wrapper">
                    <span style={{ fontWeight: '600' }}>An Opportunity</span>
                    <div className="description">
                      It may induces a positive impact on the business
                    </div>
                  </div>
                </RadioBox>
              </div>
            </div>
          </Section>
          <FormSection>
            {/* col 1 */}
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage
                  id={`forms.utc.declare.section.describe.title.${this.props.type}`}
                />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage
                  id={`forms.utc.declare.section.describe.desc.${this.props.type}`}
                />
              </FormSectionDesc>
            </FormSectionLeftSide>
            {/* col 2 */}
            <FormSectionRightSide>
              <Field component={renderObjectives} name="objectives" />
              <FormControl component="div" style={{ margin: '0.5rem 0' }}>
                <Field
                  name="responsability"
                  component={renderFormControlSelect}
                  label={intl.formatMessage({
                    id: 'forms.utc.declare.projectpackage.label',
                  })}
                  controlProps={{
                    component: 'div',
                    margin: 'dense',
                    fullWidth: true,
                  }}
                  margin="dense"
                >
                  <MenuItem value="hr">Human Resources</MenuItem>
                  <MenuItem value="lc">Logistics</MenuItem>
                  <MenuItem value="mk">Marketing</MenuItem>
                  <MenuItem value="ac">Accounting</MenuItem>
                </Field>
              </FormControl>
              <FormControl component="div" style={{ margin: '0.5rem 0' }}>
                <Field
                  name="topic"
                  component={renderFormControlSelect}
                  label={intl.formatMessage({
                    id: `forms.utc.declare.type.label.${this.props.type}`,
                  })}
                  controlProps={{
                    component: 'div',
                    margin: 'dense',
                    fullWidth: true,
                  }}
                  margin="dense"
                >
                  {/* <optgroup label="Technical"> */}
                  <MenuTitle>Technical</MenuTitle>
                  <MenuItem value="os">Outsourcing</MenuItem>
                  <MenuItem value="te">Technology</MenuItem>
                  <MenuItem value="cx">Complexity</MenuItem>
                  {/* </optgroup> */}
                  {/* <optgroup label="External"> */}
                  <MenuTitle>External</MenuTitle>
                  <MenuItem value="sc">Subcontractors</MenuItem>
                  <MenuItem value="rg">Regulatory</MenuItem>
                  <MenuItem value="mk">Market</MenuItem>
                  <MenuItem value="ct">Customer</MenuItem>
                  <MenuItem value="wt">Weather</MenuItem>
                  {/* </optgroup> */}
                  {/* <optgroup label="Organizational"> */}
                  <MenuTitle>Organizational</MenuTitle>
                  <MenuItem value="pd">Project dependencies</MenuItem>
                  <MenuItem value="rs">Resources</MenuItem>
                  <MenuItem value="fd">Funding</MenuItem>
                  <MenuItem value="pz">Priorization</MenuItem>
                  {/* </optgroup> */}
                  {/* <optgroup label="Project Management"> */}
                  <MenuTitle>Project Management</MenuTitle>
                  <MenuItem value="es">Estimating</MenuItem>
                  <MenuItem value="pn">Planning</MenuItem>
                  <MenuItem value="cl">Controlling</MenuItem>
                  <MenuItem value="cc">Communication</MenuItem>
                  {/* </optgroup> */}
                </Field>
              </FormControl>
              <Field
                name="description"
                component={renderTextField}
                placeholder={intl.formatMessage({
                  id: `forms.utc.declare.description.placeholder.${this.props.type}`,
                })}
                rows="4"
                rowsMax="4"
                label="Description"
                margin="dense"
                multiline={true}
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id={`forms.utc.declare.section.name.title.${this.props.type}`} />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage id={`forms.utc.declare.section.name.desc.${this.props.type}`} />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                name="title"
                component={renderTextField}
                placeholder="Ex: Subcontractor not able to deliver on time"
                label="Pick a descriptive title"
                margin="dense"
                multiline={false}
                inputProps={{ maxLength: 100 }}
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id="forms.utc.declare.section.cause.title" />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage id={`forms.utc.declare.section.cause.desc.${this.props.type}`} />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                rows="4"
                multiline
                name="cause"
                placeholder="Ex: Subcontractor operating in overcapacity"
                label={intl.formatMessage({
                  id: `forms.utc.declare.cause.label.${this.props.type}`,
                })}
                component={renderTextField}
                fullWidth
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id="forms.utc.declare.section.assess.title" />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage id={`forms.utc.declare.section.assess.desc.${this.props.type}`} />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                component={renderFormRadioControl}
                name="schedule"
                aria-label="schedule"
                label="Estimate the impact on schedule:"
              >
                <FormControlLabel
                  value="s1"
                  control={<Radio color="primary" />}
                  label="Delay < 1 week"
                />
                <FormControlLabel
                  value="s2"
                  control={<Radio color="primary" />}
                  label="1 week to 1 month"
                />
                <FormControlLabel
                  value="s3"
                  control={<Radio color="primary" />}
                  label="1 month to 3 months"
                />
                <FormControlLabel
                  value="s4"
                  control={<Radio color="primary" />}
                  label="> 3 months"
                />
              </Field>
              <Field
                component={renderFormRadioControl}
                name="costs"
                aria-label="costs"
                label="Estimate the impact on costs:"
              >
                <FormControlLabel value="c1" control={<Radio color="primary" />} label="1 M$" />
                <FormControlLabel
                  value="c2"
                  control={<Radio color="primary" />}
                  label="1 M$ to 5 M$"
                />
                <FormControlLabel
                  value="c3"
                  control={<Radio color="primary" />}
                  label="5 M$ to 20 M$"
                />
                <FormControlLabel value="c4" control={<Radio color="primary" />} label="> 20 M$" />
              </Field>
              <Field
                component={renderFormRadioControl}
                name="performance"
                aria-label="performance"
                label="Estimate the impact on performance:"
              >
                <FormControlLabel
                  value="p1"
                  control={<Radio color="primary" />}
                  label="Negligible"
                />
                <FormControlLabel value="p2" control={<Radio color="primary" />} label="Small" />
                <FormControlLabel
                  value="p3"
                  control={<Radio color="primary" />}
                  label="Substantial"
                />
                <FormControlLabel value="p4" control={<Radio color="primary" />} label="Huge" />
              </Field>
              <Field
                component={renderFormRadioControl}
                name="occurence"
                aria-label="occurence"
                label="And guess the probability of occurence:"
              >
                <FormControlLabel
                  value="o1"
                  control={<Radio color="primary" />}
                  label="Low (< 5%)"
                />
                <FormControlLabel
                  value="o2"
                  control={<Radio color="primary" />}
                  label="Medium (5% to 60%)"
                />
                <FormControlLabel
                  value="o3"
                  control={<Radio color="primary" />}
                  label="High (30% to 60%)"
                />
                <FormControlLabel
                  value="o4"
                  control={<Radio color="primary" />}
                  label="Very High (> 60%)"
                />
              </Field>
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide>
              <FormSectionTitle>
                <FormattedMessage id="forms.utc.declare.section.suggestion.title" />
              </FormSectionTitle>
              <FormSectionDesc>
                <FormattedMessage
                  id={`forms.utc.declare.section.suggestion.desc.${this.props.type}`}
                />
              </FormSectionDesc>
            </FormSectionLeftSide>
            <FormSectionRightSide>
              <Field
                name="suggestion"
                component={renderTextField}
                placeholder="Ex: Organize workshop with contractors and production team to review production capacity"
                rows="4"
                label={intl.formatMessage({
                  id: 'forms.utc.declare.suggestion.label',
                })}
                multiline
              />
            </FormSectionRightSide>
          </FormSection>
          <FormSection>
            <FormSectionLeftSide />
            <FormSectionRightSide>
              <FlexDiv>
                <div style={{ marginRight: '2rem' }}>Attach a document</div>
                <Button variant="fab" aria-label="add_file" onClick={this._openFileDialog}>
                  <AddIcon />
                </Button>
                <input
                  name="document"
                  type="file"
                  ref={el => (this.upload = el)}
                  style={{ display: 'none' }}
                  // accept={this.props.accept}
                  onChange={e => {
                    this.props.onDocumentUpload(e.target.files[0])
                    e.target.value = ''
                  }}
                />
              </FlexDiv>
              <FlexDiv>
                <Documents onRemove={this.props.onDocumentRemove} />
              </FlexDiv>
            </FormSectionRightSide>
          </FormSection>
          <FormSection justifyContent="flex-end">
            <div style={{ flex: 1, maxWidth: '20rem' }} />
          </FormSection>
          <FormSection justifyContent="flex-end">
            <div style={{ flex: 1, maxWidth: '20rem' }} />
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                marginLeft: '5rem',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                Submit
              </Button>
              <Button
                size="large"
                color="primary"
                style={{ margin: '0 2rem' }}
                disabled={submitting}
                onClick={this._handleSave}
              >
                Save for later
              </Button>
            </div>
          </FormSection>
        </form>
      )
  }
}

const renderObjectives = ({ meta: { touched, error } }) => (
  <FormControl component="div" style={{ margin: '0 0 0.5rem' }} error={!!(touched && error)}>
    <StyledFormLabel component="label">
      <FormattedMessage id="forms.utc.declare.impacts.objectives.label" />
    </StyledFormLabel>
    <FormGroup style={{ margin: '0 0.5rem' }}>
      <StyledFormControlLabel
        control={<Field name="obTime" type="checkbox" color="primary" component={renderCheckbox} />}
        label="Finish on time"
      />
      <StyledFormControlLabel
        control={
          <Field type="checkbox" name="obCosts" color="primary" component={renderCheckbox} />
        }
        label="Within costs"
      />
      <StyledFormControlLabel
        control={
          <Field type="checkbox" name="obQuality" color="primary" component={renderCheckbox} />
        }
        label="High Quality"
      />
      <StyledFormControlLabel
        control={
          <Field type="checkbox" name="obGuidelines" color="primary" component={renderCheckbox} />
        }
        label="Respect HSE guidelines"
      />
    </FormGroup>
    <FormHelperText>{touched && error ? error.toString() : ' '}</FormHelperText>
  </FormControl>
)

const Documents = formValues('documents')(DocumentList)

function scrollToFirstFieldInError(errors) {
  if (!errors) return
  const fieldName = Object.keys(errors)[0]
  if (document.querySelectorAll(`[name="${fieldName}"]`).length) {
    const element = document.querySelector(`[name="${fieldName}"]`)
    const position = getPosition(element)
    scroll.scrollTo(position.y - 70, {
      smooth: true,
      duration: 500,
    })
    element.focus({ preventScroll: true })
  }
}

function getPosition(el) {
  let xPos = 0
  let yPos = 0
  while (el) {
    // for all other non-BODY elements
    xPos += el.offsetLeft - el.scrollLeft + el.clientLeft
    yPos += el.offsetTop - el.scrollTop + el.clientTop
    el = el.offsetParent
  }
  return { x: xPos, y: yPos }
}

DeclareForm = reduxForm({
  form: FORM_NAME,
  validate,
  //destroyOnUnmount: false,
  enableReinitialize: true,
  touchOnBlur: false,
  onSubmitFail: (errors, dispatch) => {
    console.error('Submit failed. Error:', errors) // <-- always undefined
    scrollToFirstFieldInError(errors)
  },
})(DeclareForm)

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = state => ({
  type: selector(state, 'type'),
})

export default injectIntl(connect(mapStateToProps)(DeclareForm))
