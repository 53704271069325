import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const BlocContent = styled.div`
  background-color: white;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
`
export const BlocContainer = styled.div`
  padding-top: 1rem;
`
const Bloc = ({ title, children, contentProps = {}, ...rest }) => {
  return (
    <BlocContainer style={rest.style} {...rest}>
      {title || '\u00A0'}
      <BlocContent style={contentProps}>{children}</BlocContent>
    </BlocContainer>
  )
}

Bloc.propTypes = {
  title: PropTypes.string,
  contentProps: PropTypes.object,
}

export { Bloc }
